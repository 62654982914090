import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes }  from '@angular/router';
import { EducateToolComponent } from './educate-tool/educate-tool.component';
import { HalomtrToolModule } from '@app/halomtr-tool/halomtr-tool.module';
import { SharedModule } from '@app/shared/shared.module';


@NgModule({
  declarations: [EducateToolComponent],
  imports: [
    CommonModule,
    HalomtrToolModule,
    RouterModule,
    SharedModule
  ]
})
export class EducateModule { }

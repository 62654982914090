

export const GlareShader = `
      // precision lowp float;
      uniform float radius;
      uniform float intensity;
      uniform float aspectRatio;
       uniform vec2 base; //vec2( 0.54, 0.38 );
       uniform vec4 color;
       varying vec2 vUv;

      void main()
      {

        float normalizedX = (vUv.x - base.x )*aspectRatio ;
         float normalizedY = vUv.y - base.y;

       // float normalizedX = vUv.x;
       // float normalizedY = vUv.y ;

          if (sqrt(normalizedX * normalizedX + normalizedY * normalizedY) < radius) {
            gl_FragColor =  color;
            // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
          } else {
            gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
            // gl_FragColor = vec4(1.0, 1.0, 0.0, 1.0);
          }


          // if (normalizedX<radius) {
          //   gl_FragColor =  color;
          //   // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
          // } else {
          //   gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
          //   // gl_FragColor =  color;
          //   // gl_FragColor = vec4(1.0, 1.0, 0.0, 1.0);
          // }

      }`;

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { isNil } from 'lodash';
import { IolService } from '@app/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-iol-select-parameter',
  templateUrl: './iol-select-parameter.component.html',
  styleUrls: ['./iol-select-parameter.component.scss']
})
export class IolSelectParameterComponent implements OnInit, OnDestroy {
@Input('overallFormGroup')overallFormGroup;
@Input('showErrors')showErrors=false;
  sub;
  iolManufacturers;
  selectedIolManufacturer;
  selectedIolModel;
  iolModels;
  isDropdownManufacturer=false;
  isDropdownModel=false;
  constructor(private iolService:IolService) { }

  ngOnInit(): void {

    this.initManufacturer();
    this.sub = this.iolService.onLoaded.subscribe(res=>{
      this.iolManufacturers = this.iolService.getIolManufacturers();
    })
  }

  initManufacturer()
  {
    this.iolManufacturers = this.iolService.getIolManufacturers();

    if(!isNil(this.iolManufacturers) && !isNil(this.overallFormGroup.get('iolManufacturerId').value))
    {
      for(let iolManuf of this.iolManufacturers)
      {
        if(iolManuf.id === this.overallFormGroup.get('iolManufacturerId').value)
        {
          this.selectedIolManufacturer = iolManuf;
          this.iolModels = iolManuf.models;
          this.initModel();
          break;
        }
      }
    }
  }

  initModel()
  {
    if(!isNil(this.iolModels) && !isNil(this.overallFormGroup.get('iolModelId').value))
    {
      for(let iolMod of this.iolModels)
      {
        if(iolMod.id === this.overallFormGroup.get('iolModelId').value)
        {
          this.selectedIolModel = iolMod;
          break;
        }
      }
    }
  }

  selectIolManufacturer(iolManufacturer)
  {
    this.selectedIolManufacturer = iolManufacturer;
    this.isDropdownManufacturer = false;
    if(!isNil(iolManufacturer) && !isNil(iolManufacturer.id))
    {
      this.overallFormGroup.get('iolManufacturerId').setValue(iolManufacturer.id);
    }else{
      this.overallFormGroup.get('iolManufacturerId').setValue(null);
    }

    if(!isNil(iolManufacturer) && !isNil(iolManufacturer.models))
    {
      this.iolModels = iolManufacturer.models;
    }else{
      this.iolModels = [];
    }

    this.overallFormGroup.get('iolModelId').setValue(null);
    this.selectedIolModel = null;


  }

  selectIolModel(model)
  {
    this.selectedIolModel = model;
    this.isDropdownModel = false;

    if(!isNil(model) && !isNil(model.id))
    {
      this.overallFormGroup.get('iolModelId').setValue(model.id);
    }else{
      this.overallFormGroup.get('iolModelId').setValue(null);
    }
  }

  ngOnDestroy()
  {
    if(this.sub)
    {
      this.sub.unsubscribe();
    }
  }

}



<nav class="nav"  *ngIf="style!=='editor'">
  <div class="left">
    <a class="nav-item btn" [ngClass]="style==='assess'?'btn btn-branding-secondary-square-outline':'btn btn-branding-square-outline'" routerLink="">Home</a>
    <a class="nav-item btn" [ngClass]="style==='assess'?'btn btn-branding-secondary-square-outline':'btn btn-branding-square-outline'" [routerLink]="['/history']">History</a>
  </div>
  <div class="right">
    <app-logout-button [style]="style"></app-logout-button>
  </div>
</nav>


<nav class="nav transparent" *ngIf="style==='editor'">
  <div class="left">
    <a class="nav-item btn btn-branding-secondary-square-outline" [routerLink]="backLink"><i class='fa fa-arrow-left'></i> Back</a>
    <!-- <a class="nav-item btn" [ngClass]="style==='assess'?'btn btn-branding-secondary-square-outline':'btn btn-branding-square-outline'" [routerLink]="['/history']">History</a> -->
  </div>
  <div class="right">
    <!-- <app-logout-button [style]="style"></app-logout-button> -->
  </div>
  </nav>



<app-navbar [style]="'assess'"></app-navbar>

<app-page-layout [type]="'assess'" [description]="description">

  <div class="form-container">


      <div class="form-container-2">
        <div class="label" >IS THE PATIENT EXPERIENCING ABERRATIONS?</div>
        <div class="optns">
          <button class="btn btn-dark-square-outline fi" (click)='setYes()'>Yes</button>
          <button class="btn btn-dark-square-outline se"  (click)='setNo()'>No</button>
        </div>
        <br/>
        <button class="btn btn-large btn-dark-square-outline" (click)="back()"><i class="fa fa-arrow-left"></i> Back</button>
      </div>
    <!-- <app-patient-form [patientForm]="patientForm" [label]="'PLEASE FILL IN PATIENT INFORMATION'" (onNext)="next()" (onBack)="back()"></app-patient-form> -->
  </div>
</app-page-layout>

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-knob',
  templateUrl: './knob.component.html',
  styleUrls: ['./knob.component.scss']
})
export class KnobComponent implements OnInit {
// @Input('knobForm')knobForm;
@Input('knob')knob;
@Output('onChange')onChange = new EventEmitter(true);
  options;
  constructor() { }

  ngOnInit(): void {
    // this.options={
    //   floor:this.knobForm.get('min').value,
    //   ceil:this.knobForm.get('max').value,
    // }
    let step = (this.knob.max-this.knob.min)/100;
    this.options={
      floor:this.knob.min,
      ceil:this.knob.max,
      step: step,
    }
  }

  change(val)
  {
    this.onChange.emit(this.knob.value);
  }

}

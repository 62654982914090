import { Component, OnInit } from '@angular/core';
import { AssessService } from '@app/core';
import { Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import { isNil } from 'lodash';
@Component({
  selector: 'app-assess-part-one',
  templateUrl: './assess-part-one.component.html',
  styleUrls: ['./assess-part-one.component.scss']
})
export class AssessPartOneComponent implements OnInit {

  description;
  patient;
  isNotFound=false;
  constructor(private assessService:AssessService, private router:Router) { }

  ngOnInit(): void {
    this.assessService.resetForm();
    this.description=  Constants.assessDescription;
  }

  selectPatient(patient)
  {
    this.patient=patient;
    this.isNotFound=isNil(this.patient);
  }

  proceedWithPatient()
  {
    this.assessService.setPatient(this.patient);
    // jump to next step;
    this.nextStep();
  }

  nextStep()
  {
    this.router.navigate(['/assess-part-two'], { });
  }

}

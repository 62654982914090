import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { InputModule } from '@app/input/input.module';
import { SharedModule } from '@app/shared/shared.module';
import { RetrievePatientComponent } from './retrieve-patient/retrieve-patient.component';
import { PatientFormComponent } from './patient-form/patient-form.component';



@NgModule({
  declarations: [RetrievePatientComponent, PatientFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputModule,
    SharedModule
  ],
  exports: [RetrievePatientComponent, PatientFormComponent]
})
export class PatientModule { }

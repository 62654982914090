


<div class="page-layout-container">
  <!-- <app-navbar></app-navbar> -->

  <div class="counsel-page-container" [ngClass]="location">
    <div class="item-container">
      <div class="item left" *ngIf="examLight"  (click)="setLocation('left')">
        <app-halomtr-screen [exam]="examLight"[examType]="examTypeLight" [label]="'Light'"></app-halomtr-screen>
      </div>
      <div class="item middle"  *ngIf="examMedium"  (click)="setLocation('middle')">
        <app-halomtr-screen [exam]="examMedium"[examType]="examTypeMedium"  [label]="'Medium'"></app-halomtr-screen>
      </div>
      <div class="item right" *ngIf="examStrong" (click)="setLocation('right')">
        <app-halomtr-screen [exam]="examStrong"[examType]="examTypeStrong"  [label]="'Severe'"></app-halomtr-screen>
      </div>
    </div>
  </div>
</div>

<div class="stats-container">


  <div class='message'>

    <div class="">
      <i class="fa fa-info-circle fa-4x"></i>
    </div>
    <div class="rr">
      We found {{ numSimilar }} similar cases in our database to generate these images.
      <br/>
      Due to the low number of previous examples, the images above may not depict a good representation of the patient profile.
    </div>
  </div>
  <div class="link">
    <a class="btn btn-branding-square-outline" [routerLink]="['']">Finish</a>
  </div>
  <!-- prediction: {{prediction | json}} -->
</div>

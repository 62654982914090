
<div class="status pending" *ngIf="status==='PENDING'">
  <i class="fa fa-2x fa-times-circle"></i>
</div>

<div class="status pending" *ngIf="status==='PROCESSING'">
  <i class="fa fa-2x fa-clock-o"></i>
</div>

<div class="status success" *ngIf="status==='SUCCESS'">
  <i class="fa fa-2x fa-check-circle"></i>
</div>

<div class="status error" *ngIf="status==='ERROR'">
  <i class="fa fa-2x fa-times-circle"></i>
</div>

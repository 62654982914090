import { Component, OnInit } from "@angular/core";
import { CounselService } from "@app/core";
import { Router } from "@angular/router";
import { Constants } from "@app/app.constants";

@Component({
  selector: "app-counsel-patient-form",
  templateUrl: "./counsel-patient-form.component.html",
  styleUrls: ["./counsel-patient-form.component.scss"],
})
export class CounselPatientFormComponent implements OnInit {
  description;
  patientForm;
  constructor(private counselService: CounselService, private router: Router) {}

  ngOnInit(): void {
    this.description = Constants.counselDescription;
    this.patientForm = this.counselService.getPatientForm();
  }

  next() {
    console.log("next");
    this.counselService.loadPredictions().then((res) => {
      this.router.navigate(["/counsel-halomtr"], {});
    });
  }

  back() {
    this.router.navigate(["/"], {});
  }
}

import { Component, OnInit } from '@angular/core';
import { CustomValidators } from 'ng2-validation';

import { AuthService, BodyLoadingAnimationService } from '@app/core';

import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm;
  messages;
  showErrors=false;
  constructor(private authService:AuthService,
              private formBuilder: FormBuilder,
              private bodyLoadingAnimationService:BodyLoadingAnimationService
            ) { }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({

      email: [null, [Validators.required, CustomValidators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]]

  });
  }

  login() {


    this.showErrors=false;
    if(this.loginForm.valid)
    {

        this.bodyLoadingAnimationService.setIsLoading(true);
        this.messages = [];
        var email: string =   this.loginForm.get('email').value.toLowerCase().trim();
        var password: string =  this.loginForm.get('password').value;
          // try to authenticate with feathers
          this.authService.logIn({
            strategy: 'local',
            email,
            password
          })
            // navigate to base URL on success
            .then((response) => {
              //this.router.navigate(['/']);

              if(response===null)
              {
                this.bodyLoadingAnimationService.setIsLoading(false);
                this.messages.unshift('Server Error, please check your internet connection and try again');
              }else{
                this.bodyLoadingAnimationService.setIsLoading(false);
                // this.onLogin.emit(response);
              }

            })
            .catch(err => {

              this.bodyLoadingAnimationService.setIsLoading(false);
              var message = 'Error: ';
              if(typeof err.message!='undefined' && err.message!=null)
              {
                message+=err.message;
              }
              this.messages.unshift(message);
            });
    }else{
      this.showErrors=true;
    }// this.loginForm.valid

  }

}

import { Component, OnInit } from '@angular/core';
import { BodyLoadingAnimationService} from '@app/core';
@Component({
  selector: 'app-body-loading-animation',
  templateUrl: './body-loading-animation.component.html',
  styleUrls: ['./body-loading-animation.component.scss']
})
export class BodyLoadingAnimationComponent implements OnInit {

  isLoading = false;

  constructor(private bodyLoadingAnimationService:BodyLoadingAnimationService) { }

  ngOnInit() {

    this.isLoading = this.bodyLoadingAnimationService.getIsLoading();


  	this.bodyLoadingAnimationService.loadingChanged.subscribe(val=>{
  		this.isLoading = val;
  	});

  }


}

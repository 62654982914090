import { Component, Input, OnInit } from '@angular/core';
import { KnobService, FormsService } from '@app/core';
import { isNil } from 'lodash';

@Component({
  selector: 'app-star-knobs',
  templateUrl: './star-knobs.component.html',
  styleUrls: ['../halomtr-knobs/halomtr-knobs.component.scss', './star-knobs.component.scss']
})
export class StarKnobsComponent implements OnInit {
@Input('examType')examType;
  exam;
  sub;
  knobFormRadius;
  key;

  constructor(private knobService:KnobService, private formsService:FormsService) { }


  ngOnInit(): void {
    this.exam = this.knobService.getExam(this.examType);
    this.initForm();
    this.sub = this.knobService.onExamChange.subscribe(res=>{
      if(res===this.examType){
        this.exam = this.knobService.getExam(this.examType);
        this.initForm();
      }
    })
  }

  initForm()
  {

    if(!isNil(this.exam) && !isNil(this.exam.starbursts) && !isNil(this.exam.starbursts.key))
    {
      this.key = this.exam.starbursts.key;
    }
  }

  changeRadius(val)
  {
    this.knobService.updateValue(this.examType, 'starbursts', this.key, 'radius', val);
  }

  changeIntensity(val)
  {
    this.knobService.updateValue(this.examType, 'starbursts', this.key, 'intensity', val);
  }

  changeThickness(val)
  {
    this.knobService.updateValue(this.examType, 'starbursts', this.key, 'thickness', val);
  }




  ngOnDestroy()
  {
    this.sub.unsubscribe();
  }

}

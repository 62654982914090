<app-navbar></app-navbar>
<div class="page-container">


  <div class="top-page-container">
    <div class="left-page-container">
      <span routerLink="/counsel" class="counsel-link">COUNSEL</span>
      <div class='page-description'>{{counselDescription}}</div>
    </div>

    <div class="right-page-container">
      <span routerLink="/assess" class="assess-link">ASSESS</span>
      <div class='page-description'>{{assessDescription}}</div>
    </div>

  </div>
  <div class="or-container">
    <div class="or-content">
      OR
    </div>
    <div class="circle-container">

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px" viewBox="-10 -10 220 220">
      <defs>
        <linearGradient id="redyel" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stop-color="#4df2ac"/>
            <stop offset="100%" stop-color="#69f2fd"/>
        </linearGradient>
        <linearGradient id="yelgre" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stop-color="#69f2fd"/>
            <stop offset="100%" stop-color="#69f2fd"/>
        </linearGradient>
        <linearGradient id="grecya" gradientUnits="objectBoundingBox" x1="1" y1="0" x2="0" y2="1">
            <stop offset="0%" stop-color="#69f2fd"/>
            <stop offset="100%" stop-color="#fb5f26"/>
        </linearGradient>
        <linearGradient id="cyablu" gradientUnits="objectBoundingBox" x1="1" y1="1" x2="0" y2="0">
            <stop offset="0%" stop-color="#fb5f26"/>
            <stop offset="100%" stop-color="#fb5f26"/>
        </linearGradient>
        <linearGradient id="blumag" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="0" y2="0">
            <stop offset="0%" stop-color="#fb5f26"/>
            <stop offset="100%" stop-color="#4df2ac"/>
        </linearGradient>
        <linearGradient id="magred" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="1" y2="0">
            <stop offset="0%" stop-color="#4df2ac"/>
            <stop offset="100%" stop-color="#4df2ac"/>
        </linearGradient>
      </defs>

      <g fill="none" stroke-width="15" transform="translate(100,100)">
        <path d="M 0,-100 A 100,100 0 0,1 86.6,-50" stroke="url(#redyel)"/>
        <path d="M 86.6,-50 A 100,100 0 0,1 86.6,50" stroke="url(#yelgre)"/>
        <path d="M 86.6,50 A 100,100 0 0,1 0,100" stroke="url(#grecya)"/>
        <path d="M 0,100 A 100,100 0 0,1 -86.6,50" stroke="url(#cyablu)"/>
        <path d="M -86.6,50 A 100,100 0 0,1 -86.6,-50" stroke="url(#blumag)"/>
        <path d="M -86.6,-50 A 100,100 0 0,1 0,-100" stroke="url(#magred)"/>
      </g>
    </svg>

    </div>


  </div>
  <div class="bottom-page-container">
    <span routerLink="/educate" class="educate-link">EDUCATE</span>
    <div class='page-description'>{{educateDescription}}</div>
    <div class="dev-container">
      <app-set-dev-mode></app-set-dev-mode>
    </div>
  </div>



</div>

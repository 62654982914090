import { Component, Input, OnInit } from '@angular/core';
import { isNil } from 'lodash';

@Component({
  selector: 'app-number-select-parameter',
  templateUrl: './number-select-parameter.component.html',
  styleUrls: ['./number-select-parameter.component.scss']
})
export class NumberSelectParameterComponent implements OnInit {
  @Input('label')label;
  @Input('min')min=0.0;
  @Input('max')max=1.0;
  @Input('showErrors')showErrors=false;
  @Input('init')init=0.5;
  @Input('increment')increment=0.1;
  @Input('parameterFormGroup')parameterFormGroup;

  options=[];
  constructor() { }

  ngOnInit(): void {
    this.createOptions();
  }

  createOptions()
  {
    for(let i=this.min; i<=this.max; i=i+this.increment)
    {

      let newVal = i;//i.toPrecision(this.precision)
      // if(i<=tmpInit)
      // {
      //   // this.selected=newVal;
      // }
      this.options.push(newVal);
    }
  }

}

import { Injectable, Output, EventEmitter } from '@angular/core';
import { DataService } from './data.service';
import { map  } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EducateService {
@Output('onExamChange')onExamChange = new EventEmitter(true);
  exam;
  constructor(private dataService:DataService) { }

  init()
  {
    this.loadExam();
  }

  loadExam()
  {
    this.dataService.dataNever('exams', {name:"production", $limit:1})
    // .map(m=>m.data)
    .subscribe(res=>{
      if(res.total>0)
      {
        this.exam = res.data[0];
        this.onExamChange.emit(this.exam);
      }
    })
  }

  getExam()
  {
    return this.exam;
  }
}

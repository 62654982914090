import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js';
import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader.js';
import { fromEvent, Observable, Subscription } from "rxjs";
import { VertexShader, HaloShader, GlareShader } from '@app/shaders';
import { ResizedEvent } from 'angular-resize-event';
@Component({
  selector: 'app-halomtr-screen',
  templateUrl: './halomtr-screen.component.html',
  styleUrls: ['./halomtr-screen.component.scss']
})
export class HalomtrScreenComponent implements OnInit {
@Input('exam')exam;
@Input('blurs')blurs;
@Input('label')label;
@Input('halos')halos;
@Input('stars')stars;
@Input('examType')examType;
@Input('knobService')knobService;
@ViewChild('screenContainer') screenContainer:ElementRef;
@ViewChild('canvasContainer') canvasContainer:ElementRef;

resizeObservable$: Observable<Event>
resizeSubscription$: Subscription;
  aspectRatio;
  scene;
  camera;
  renderer;
  imageWidth=3200;
  imageHeight=3550;
  canvasWidth;
  canvasHeight;
  composer;
  isLoaded=false;
  constructor() { }

  ngOnInit(): void {

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      console.log('resizeSubscription')
      // this.resizeCanvas();
    })

    this.scene = new THREE.Scene();

    let w = window.innerWidth/4;
    let h = window.innerHeight/2;


    this.camera = new THREE.OrthographicCamera( -1*w, w, h, -1 * h, 0.1, 1000);
    this.camera.position.z = 5;
    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setSize( w*2, h*2 );
    this.animate();
    // this.resizeCanvas();
  }

  addElements()
  {
    // this.addGlares();
    // this.addHalos();

  }

  ngAfterViewInit()
  {

    setTimeout(() => {
      this.render()
      this.isLoaded=true
    });
  }

  onResized(event: ResizedEvent) {
    // this.width = event.newWidth;
    // this.height = event.newHeight;
    // console.log('resss ized');
    this.resizeCanvas();
  }

  resizeCanvas()
  {
    // https://stackoverflow.com/questions/32189831/three-js-scene-not-rendering
    let bond = this.screenContainer.nativeElement.getBoundingClientRect();
    this.aspectRatio = this.imageWidth/ this.imageHeight;
    let newW = Math.ceil(bond.height * this.aspectRatio);
    let newH = Math.ceil(bond.height);
    // let newW = bond.height * this.aspectRatio;
    // let newH = bond.height;

    this.canvasWidth = newW;
    this.canvasHeight = newH;
    this.camera.left = -1* newW/2;
    this.camera.right = newW/2;
    this.camera.top = newH/2;
    this.camera.bottom = -1 * newH/2;
    this.renderer.setSize( newW, newH );
    this.camera.updateProjectionMatrix();
    // this.needupdate = true;

  // this.scene.remove.apply(this.scene, this.scene.children);
      // this.addElements()


    // this.renderer.render(this.scene, this.camera);
    // this.addElements();

  }

  render()
  {
    this.resizeCanvas();
    this.canvasContainer.nativeElement.append( this.renderer.domElement);
     // this.screenContainer.nativeElement.insertBefore( this.renderer.domElement, this.screenContainer.nativeElement.firstChild );
     // this.renderer.render( this.scene, this.camera );

     this.animate();
  }

  getBulbPosition(bulb)
  {
    let position = {
      x: bulb.base[0]*this.canvasWidth-this.canvasWidth/2,
      y: bulb.base[1]*this.canvasHeight-this.canvasHeight/2
    }

    return position;
  }

  addGlares()
  {
    let i=0;
    for(let bulb of this.exam.bulbs)
    {
      let geometry = new THREE.PlaneGeometry( 300, 300 );
      let color = bulb.color;
      let position = this.getBulbPosition(bulb);

      let uniforms={
        radius:{value: 0.5},
        aspectRatio:{value:1.0},
        thickness: {value: 2},
        intensity:{value:0.5},
        base: {value:new THREE.Vector2( 0.5, 0.5 )},//position,//new Uniform( new Vector2(position.x, position.y) ) ,
        color:  {value:new THREE.Vector4( color[0], color[1], color[2], 0.5 )}
      }
      let material =  new THREE.ShaderMaterial({
         uniforms: uniforms,
         fragmentShader: GlareShader,
         vertexShader: VertexShader,
       })

      let glare = new THREE.Mesh( geometry, material );


      glare.position.setX(position.x);
      glare.position.setY(position.y);


      this.scene.add( glare );
      this.scene.updateMatrix()
      i++;
    }

  }

  addHalos()
  {
    let i=0;
    for(let bulb of this.exam.bulbs)
    {
      let geometry = new THREE.PlaneGeometry( 100, 100 );
      let color = bulb.color;
      let position = this.getBulbPosition(bulb);


      // let geometry = new THREE.RingGeometry( 20, 28, 256 );

      let uniforms={
        radius:1.0,
        aspectRatio:1.0,
        thickness: 2,
        base:position,
        color: color
      }
      let material =  new THREE.ShaderMaterial({
         uniforms: uniforms,
         fragmentShader: HaloShader,
         vertexShader: VertexShader,
       })

      let halo = new THREE.Mesh( geometry, material );

      halo.position.setX(position.x);
      halo.position.setY(position.y);

      // cube.position.setX(x);
      // cube.position.setY(y);

      // cube.matrixWorld.setPosition(new THREE.Vector3(bulb.base[0], bulb.base[1], 0))


      this.scene.add( halo );
      this.scene.updateMatrix()
      i++;
    }
  }

   animate() {


       // this.renderer.render( this.scene, this.camera );


      //https://stackoverflow.com/questions/15354117/three-js-blur-the-frame-buffer
      this.composer = new EffectComposer( this.renderer );
      this.composer.addPass( new RenderPass( this.scene, this.camera ) );

      let tdiff = 0.0;
      let vBlur = 2.0 / 512.0;
      // let horz = HorizontalBlurShader;
      // horz.uniforms.tDiffuse.value = tdiff
      // horz.uniforms.h.value = vBlur;
      //
      // let hblur = new ShaderPass( horz );
      // this.composer.addPass( hblur );
      //
      // let vert = VerticalBlurShader;
      // vert.uniforms.tDiffuse.value = tdiff
      // vert.uniforms.v.value = vBlur
      // // vert.uniforms = {
      // //   "tDiffuse":  new THREE.Uniform( new THREE.Number(20.0) ),
    	// // 	"v": new THREE.Uniform( new THREE.Number(1.0 / 512.0) ),//{ value: 1.0 / 512.0 }
      // // }
      // let vblur = new ShaderPass( vert );
      // // set this shader pass to render to screen so we can see the effects
      // vblur.renderToScreen = true;
      // this.composer.addPass( vblur );


      this.addBlurEffect(this.composer, tdiff, vBlur, 16)
      this.composer.render();


    }

    addBlurEffect(composer, diffuse, factor, passes)
    {

      let vert = VerticalBlurShader;
      vert.uniforms.tDiffuse.value = diffuse;
      vert.uniforms.v.value = factor;

      let horz = HorizontalBlurShader;
      horz.uniforms.tDiffuse.value = diffuse;
      horz.uniforms.h.value = factor;


      for(let i=0; i<passes; i++)
      {
        let hblur = new ShaderPass( horz );
        hblur.renderToScreen = true;
        composer.addPass( hblur );

        let vblur = new ShaderPass( vert );
        // set this shader pass to render to screen so we can see the effects
        vblur.renderToScreen = true;
         composer.addPass( vblur );
      }



    }

    ngOnDestroy()
    {
       this.resizeSubscription$.unsubscribe();
    }

}

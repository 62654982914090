import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EducateRoutes } from '@app/educate/educate.routes';
import { AssessRoutes } from '@app/assess/assess.routes';
import { CounselRoutes } from '@app/counsel/counsel.routes';
import { MainRoutes } from '@app/main/main.routes';

const routes: Routes = [
  ...EducateRoutes,
  ...AssessRoutes,
  ...CounselRoutes,
  ...MainRoutes
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

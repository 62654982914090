import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { ConfirmMessageComponent } from './confirm-message/confirm-message.component';



@NgModule({
  declarations: [ConfirmDeleteComponent, ConfirmMessageComponent],
  imports: [
    CommonModule
  ]
})
export class ModalsModule { }

import { Injectable, Output, EventEmitter } from '@angular/core';
import { DataService } from './data.service';
// import { Shortid = require('shortid');
import * as shortid from 'shortid';

@Injectable({
  providedIn: 'root'
})
export class KnobService {
@Output('onExamChange')onExamChange = new EventEmitter(true);
@Output('onChange')onChange = new EventEmitter(true);
@Output('onAddHalo')onAddHalo = new EventEmitter(true);

  initExam;
  exams={
    'production':null,
    'educate':null,
    'counselLight': null,
    'counselMedium': null,
    'counselStrong': null
  }
  constructor(private dataService:DataService) {
    // this.init();
  }

  init()
  {
    this.loadExam('production');
    this.loadExam('educate');
    this.loadExam('counselLight');
    this.loadExam('counselMedium');
    this.loadExam('counselStrong');
  }

  loadExam(examType)
  {
    this.dataService.dataNever('exams', {name:examType, $limit:1})
    .subscribe(res=>{
      if(res.total>0)
      {
        this.exams[examType] = res.data[0];
        if(examType==='production')
        {
          this.initExam = JSON.parse(JSON.stringify(res.data[0]));
        }
        this.onExamChange.emit(examType);
      }
    })
  }

  getExam(examType)
  {
    return this.exams[examType];
  }

  setExam(examType, exam)
  {
    this.exams[examType] = exam;
    this.onExamChange.emit(examType);
  }

  updateValue(examType, knobType, key, knobElement, val)
  {
    if(knobType==='halos')
    {
      for(let index=0; index<this.exams[examType][knobType].length; index++)
      {
        if(this.exams[examType][knobType][index].key === key)
        {
          this.exams[examType][knobType][index][knobElement].value = val;
          this.onChange.emit(this.exams[examType][knobType][index]);
        }

      }
    }else{
      if(this.exams[examType][knobType].key === key)
      {
        this.exams[examType][knobType][knobElement].value = val;
        this.onChange.emit(this.exams[examType][knobType]);
        // this.onChange({examType, knobType, key, knobElement, val});
      }
    }


  }

  generateID(){
    return shortid.generate();
    // return JSON.stringify(Math.random()).slice(15);
  }

  addHalo(examType)
  {
    let initHalo = JSON.parse(JSON.stringify(this.initExam.halos[0]));

    let newKey = this.generateID();
    let item = {
      key:newKey,
      type: 'halo',
      intensity: initHalo.intensity,
      radius: initHalo.radius,
      thickness: initHalo.thickness
    }
    this.exams[examType].halos.push(item)

    this.onChange.emit(item);
    this.onAddHalo.emit(examType);
    this.onExamChange.emit(examType);
  }

  removeHalo(examType, halo)
  {
    let index=-1;
    for(let i=0; i<this.exams[examType].halos.length; i++)
    {
      if(this.exams[examType].halos[i].key===halo.key)
      {
        index=i;
        break;
      }
    }

    if(index>=0)
    {
      this.exams[examType].halos.splice(index, 1);
      this.onExamChange.emit(examType);
    }
  }
}

import { Injectable } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { cloneDeep } from "lodash";
import { CustomValidators } from "ng2-validation";

@Injectable({
  providedIn: "root",
})
export class FormsService {
  constructor(private formBuilder: FormBuilder) {}

  generateKnobForm(initialValues) {
    var editForm = this.formBuilder.group({
      min: null,
      max: null,
      value: null,
      key: null,
      type: null,
    });

    if (initialValues !== null) {
      editForm.patchValue(initialValues);
    }

    return cloneDeep(editForm);
  }

  generatePatientForm(initialValues) {
    var editForm = this.formBuilder.group({
      age: null,
      patientType: null, //assess or counsel
      OD: null,
      OS: null,
      iolCode: null,
      internalID: null,
      selectedEye: null,
      prescription: null,
      pupilDilation: null,
      lensPower: null,
      preOpRefraction: null,
      iolManufacturerId: null,
      iolModelId: null,
      isDevMode: null,
    });

    if (initialValues !== null) {
      editForm.patchValue(initialValues);
    }

    return cloneDeep(editForm);
  }

  applyPatientFormForAssessValidators(editForm) {
    editForm
      .get("age")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("age").updateValueAndValidity();

    editForm.get("patientType").setValidators([Validators.required]);
    editForm.get("patientType").updateValueAndValidity();

    editForm.get("selectedEye").setValidators([Validators.required]);
    editForm.get("selectedEye").updateValueAndValidity();

    editForm
      .get("lensPower")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("lensPower").updateValueAndValidity();

    editForm
      .get("pupilDilation")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("pupilDilation").updateValueAndValidity();

    editForm
      .get("preOpRefraction")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("preOpRefraction").updateValueAndValidity();

    editForm
      .get("iolManufacturerId")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("iolManufacturerId").updateValueAndValidity();

    editForm
      .get("iolModelId")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("iolModelId").updateValueAndValidity();
  }

  applyPatientFormForCounselValidators(editForm) {
    editForm
      .get("age")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("age").updateValueAndValidity();

    editForm.get("patientType").setValidators([Validators.required]);
    editForm.get("patientType").updateValueAndValidity();

    editForm.get("selectedEye").setValidators([Validators.required]);
    editForm.get("selectedEye").updateValueAndValidity();

    editForm
      .get("lensPower")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("lensPower").updateValueAndValidity();

    editForm
      .get("pupilDilation")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("pupilDilation").updateValueAndValidity();

    editForm
      .get("preOpRefraction")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("preOpRefraction").updateValueAndValidity();

    editForm
      .get("iolManufacturerId")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("iolManufacturerId").updateValueAndValidity();

    editForm
      .get("iolModelId")
      .setValidators([Validators.required, CustomValidators.number]);
    editForm.get("iolModelId").updateValueAndValidity();
  }
}

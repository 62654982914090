import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes }  from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { SharedModule } from '@app/shared/shared.module';
import { MainComponent } from './main/main.component';
import { HistoryComponent } from './history/history.component';




@NgModule({
  declarations: [MainComponent, HistoryComponent],
  imports: [
    CommonModule,
    RouterModule,
    MomentModule,
    SharedModule
  ]
})
export class MainModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeathersService } from './feathers.service';
import { DataService } from './data.service';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { AssessService } from './assess.service';
import { CounselService } from './counsel.service';
import { EducateService } from './educate.service';
import { KnobService } from './knob.service';
import { FormsService } from './forms.service';
import { IolService } from './iol.service';
import { HistoryService } from './history.service';
import { BodyLoadingAnimationService } from './body-loading-animation.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[FeathersService, DataService, AuthService, UserService, BodyLoadingAnimationService, AssessService, CounselService, EducateService, KnobService, FormsService, IolService, HistoryService]
})
export class CoreModule { }


<app-input-container [label]="'IOL Manufacturer'" [isValid]="overallFormGroup.get('iolManufacturerId').valid" [showErrors]="showErrors">

  <div class="sel" (click)="isDropdownManufacturer=!isDropdownManufacturer">
    <span *ngIf="selectedIolManufacturer">{{selectedIolManufacturer.name}}</span>
    <span *ngIf="!selectedIolManufacturer">Select</span>
  </div>


  <div class="drop" *ngIf="isDropdownManufacturer">
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let item of iolManufacturers" (click)="selectIolManufacturer(item)">{{item.name}}</li>
    </ul>
  </div>
</app-input-container>


<app-input-container [label]="'IOL Model'"  [isValid]="overallFormGroup.get('iolModelId').valid" [showErrors]="showErrors">
  <div class="sel" (click)="isDropdownModel=!isDropdownModel">
    <span *ngIf="selectedIolModel">{{selectedIolModel.name}}</span>
    <span *ngIf="!selectedIolModel">Select</span>
  </div>


  <div class="drop" *ngIf="isDropdownModel">
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let item of iolModels" (click)="selectIolModel(item)">{{item.name}}</li>
    </ul>
  </div>
</app-input-container>

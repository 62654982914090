

export const StarShader = `

        precision highp float;
        // precision lowp float;
        varying vec2 vUv;
        const float PI = 3.1415926535897932384626433832795;
        const float numIterations = 1000.0;

        uniform vec2 base; //vec2( 0.54, 0.38 );
        uniform float thickness; // 0.016;
        uniform float aspectRatio;
        uniform float radius; // 0.4;
        uniform float rotation; // PI;
        uniform vec4 color;

        
        bool equal(float a, float b) {
          return abs(a - b) < 0.001;
        }
        float angle(vec2 a, vec2 b) {
          return acos(dot(normalize(a), normalize(b)));
        }

        vec2 rotatePoint(float angle, vec2 point, vec2 center)
        {

          //https://stackoverflow.com/questions/2259476/rotating-a-point-about-another-point-2d
          float s = sin(angle);
          float c = cos(angle);

          vec2 p = vec2(point.x, point.y);
          // translate point back to origin:
          p.x -= center.x;
          p.y -= center.y;

          // rotate point
          float xnew = p.x * c - p.y * s;
          float ynew = p.x * s + p.y * c;

          // translate point back:
          p = vec2(xnew + center.x, ynew + center.y);

          return p;
        }


        bool isInRadius(vec2 vUv, vec2 base, float radius, float aspectRatio)
        {
          float normalizedX = (vUv.x - base.x )*aspectRatio ;
           float normalizedY = vUv.y - base.y;


          if (sqrt(normalizedX * normalizedX + normalizedY * normalizedY) < radius)
          {
            return true;
          }else{
            return false;
          }
        }

        float getBlurRadius(float num_bursts, float thickness, float radius, float aspectRatio)
        {

          //float angleTheta = 360.0 / (num_bursts);
          float mPI = 3.14159265359;
          float angleTheta =   (2.0*mPI)/num_bursts;
          float sideA = sqrt( pow(radius,2.0) + pow(thickness,2.0) );
          float angleM = acos(thickness / sideA);
          //float angleW = mPI/2.0- (angleTheta/2.0);
          //float angleP = mPI - angleW - angleM;
          float angleP = mPI/2.0 + (angleTheta/2.0) - angleM;
          float sideX = thickness * sin(angleM) / sin(angleP);


          return sideX;
        }

        bool insideStar(vec2 vUv, vec2 base, float thickness, float radius, float aspectRatio)
        {
          bool isInStar = false;
          float num_bursts = 32.0;

          vec2 normalizeduv = vUv;
          normalizeduv.x = vUv.x * aspectRatio;
          vec2   normalizedbase = base;
          normalizedbase.x = base.x * aspectRatio;

          float blurRadius = getBlurRadius( num_bursts, thickness,  radius,  aspectRatio);

          // // first check if outside of Radius
              if(isInRadius( vUv,  base,  radius, aspectRatio) && (isInRadius( vUv,  base,  blurRadius, aspectRatio) == false))
              {





              // for(float i =0.0; i<32.0; i=i+1.0)
              for(float i =0.0; i<32.0; i++)
              {
                // if(numIterations===num_bursts)
                // {
                //   break;
                // }
                 float rotation_local = ((2.0*3.14159265359)/num_bursts * i);

                 vec2 a = rotatePoint(rotation_local,vec2(normalizedbase.x -thickness, normalizedbase.y), normalizedbase) - normalizeduv;
                 vec2 b = rotatePoint(rotation_local,vec2(normalizedbase.x , normalizedbase.y+radius), normalizedbase)  - normalizeduv;
                 vec2 c = rotatePoint(rotation_local,vec2(normalizedbase.x +thickness, normalizedbase.y), normalizedbase)   - normalizeduv;
                 isInStar = isInStar ||  equal(angle(a, b) + angle(b, c) + angle(a, c), PI * 2.0);
             }
            return isInStar;
          }// isInRadius
          return false;
        }


        void drawTriangle(vec2 base, float thickness, float radius, float rotation, float aspectRatio, vec4 color)
        {
           gl_FragColor = insideStar(vUv, base, thickness, radius, aspectRatio) ? color : vec4(0.0);
        }

        void main() {


            drawTriangle( base,  thickness,  radius,  rotation, aspectRatio, color);

        }
      `;

<app-input-container
  [label]="label"
  [isValid]="parameterFormGroup.valid"
  [showErrors]="showErrors"
>
  <input
    class="form-control"
    [placeholder]="'...'"
    [formControl]="parameterFormGroup"
    (blur)="valueChanged()"
  />
</app-input-container>

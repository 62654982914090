import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng5SliderModule } from 'ng5-slider';
import { AngularResizedEventModule } from 'angular-resize-event';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HalomtrEditorComponent } from './halomtr-editor/halomtr-editor.component';
import { HalomtrScreenComponent } from './halomtr-screen/halomtr-screen.component';
import { HalomtrKnobsComponent } from './halomtr-knobs/halomtr-knobs.component';
import { StarKnobsComponent } from './star-knobs/star-knobs.component';
import { HaloKnobsComponent } from './halo-knobs/halo-knobs.component';
import { BlurKnobsComponent } from './blur-knobs/blur-knobs.component';
import { KnobSliderComponent } from './knob-slider/knob-slider.component';
import { HalomtrScreenOverlayBlursComponent } from './halomtr-screen-overlay-blurs/halomtr-screen-overlay-blurs.component';
import { HalomtrScreenOverlayHaloComponent } from './halomtr-screen-overlay-halo/halomtr-screen-overlay-halo.component';
import { HalomtrScreenOverlayStarComponent } from './halomtr-screen-overlay-star/halomtr-screen-overlay-star.component';
import { HalomtrScreenOverlayBulbComponent } from './halomtr-screen-overlay-bulb/halomtr-screen-overlay-bulb.component';
import { HalomtrScreenOverlayGlareComponent } from './halomtr-screen-overlay-glare/halomtr-screen-overlay-glare.component';
import { KnobComponent } from './knob/knob.component';
import { HaloKnobComponent } from './halo-knob/halo-knob.component';



@NgModule({
  declarations: [HalomtrEditorComponent, HalomtrScreenComponent, HalomtrKnobsComponent, StarKnobsComponent, HaloKnobsComponent, BlurKnobsComponent, KnobSliderComponent, HalomtrScreenOverlayBlursComponent, HalomtrScreenOverlayHaloComponent, HalomtrScreenOverlayStarComponent, HalomtrScreenOverlayBulbComponent, HalomtrScreenOverlayGlareComponent, KnobComponent, HaloKnobComponent],
  imports: [
    CommonModule,
    Ng5SliderModule,
    AngularResizedEventModule,
    NgbModule
  ],
  exports: [HalomtrEditorComponent, HalomtrScreenComponent],
})
export class HalomtrToolModule { }

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { FormsService } from './forms.service';
import { HistoryService } from './history.service';
import { isNil } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AssessService {

  patientForm;
  patient;
  emptyAssessment={
    isExperiencingAberrations:false
  }
  isDevMode;

  constructor(private authService:AuthService,
              private formsService:FormsService,
              private historyService:HistoryService
            ) {

    this.patientForm = this.formsService.generatePatientForm(null);
    this.resetForm();

    this.isDevMode = this.authService.getIsDevMode();
    this.authService.devModeChanged.subscribe(res=>{
      this.isDevMode = this.authService.getIsDevMode();
    })
  }

  resetForm()
  {
    this.patientForm.reset();
    this.formsService.applyPatientFormForAssessValidators(this.patientForm);
    this.patientForm.get('patientType').setValue('assess');
    this.patient=null;

  }

  getPatientForm()
  {
    return this.patientForm;
  }

  setPatient(patient)
  {
    this.patient = patient;
    if(!isNil(patient))
    {
      this.patientForm.patchValue(patient);
    }
  }

  getForm()
  {
    return this.patientForm;
  }

  async save()
  {
    return null;
  }

  addDataToSubmitQueue(assessment)
  {
    this.patientForm.get('isDevMode').setValue(this.isDevMode);
    let ass = JSON.parse(JSON.stringify(assessment));
    ass['isDevMode']=this.isDevMode;
    let item ={
      type: 'assess',
      patient:JSON.parse(JSON.stringify(this.patient)),
      patientForm: JSON.parse(JSON.stringify(this.patientForm.value)),
      assessment: ass,
      createdAt:new Date(),
      status: 'PENDING'
    }

    this.historyService.addToQueue(item);

    this.historyService.saveAllPending();
    // this.submitQueue.push(item);
  }


  addDataToSubmitQueueWithNoAberrations()
  {
    this.patientForm.get('isDevMode').setValue(this.isDevMode);
    this.emptyAssessment['isDevMode']=this.isDevMode;
    let item ={
      type: 'assess',
      patient:JSON.parse(JSON.stringify(this.patient)),
      patientForm: JSON.parse(JSON.stringify(this.patientForm.value)),
      assessment: this.emptyAssessment,
      createdAt:new Date(),
      status: 'PENDING'
    }

    this.historyService.addToQueue(item);

    this.historyService.saveAllPending();
    // this.submitQueue.push(item);
  }
}

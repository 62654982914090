import { Component, Input,OnInit } from '@angular/core';
import { KnobService } from '@app/core';

@Component({
  selector: 'app-halomtr-knobs',
  templateUrl: './halomtr-knobs.component.html',
  styleUrls: ['./halomtr-knobs.component.scss']
})
export class HalomtrKnobsComponent implements OnInit {
@Input('exam')exam;
@Input('examType')examType;

  tab='HALO'; // HALO | BLUR | STAR
  constructor(private knobService:KnobService) { }

  ngOnInit(): void {
  }

  setTab(val)
  {
    this.tab = val;
  }

}




<app-input-container [label]="label" [isValid]="parameterFormGroup.valid" [showErrors]="showErrors">
  <div class="select-container">
    <div class="item" [ngClass]="parameterFormGroup.value === option?'selected':''" *ngFor="let option of options" (click)="select(option)">
      {{option}}
    </div>
  </div>
</app-input-container>

import { Injectable, Output, EventEmitter } from '@angular/core';
import { DataService } from './data.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IolService {
@Output('onLoaded')onLoaded = new EventEmitter(true);

  iolManufacturers;
  serviceName="iol-manufacturers";
  constructor(private dataService:DataService) { }

  init()
  {
    this.dataService.dataNever(this.serviceName, {})
    .map(m=>m.data)
    .subscribe(res=>{
      this.iolManufacturers= res;
      this.onLoaded.emit(true);
    })
  }

  getIolManufacturers()
  {
    return this.iolManufacturers;
  }
}

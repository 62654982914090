
import { AssessFormComponent } from './assess-form/assess-form.component';
import { AssessPartOneComponent } from './assess-part-one/assess-part-one.component';
import { AssessPartTwoComponent } from './assess-part-two/assess-part-two.component';
import { AssessHalomtrComponent } from './assess-halomtr/assess-halomtr.component';
import { AssessPartThreeComponent } from './assess-part-three/assess-part-three.component';

export const AssessRoutes = [
  {
    path: "assess", component: AssessPartOneComponent,
  },
  {
    path: "assess-part-two", component: AssessPartTwoComponent,
  },
  {
    path: "assess-part-three", component: AssessPartThreeComponent,
  },
  {
    path: "assess-halomtr", component: AssessHalomtrComponent,
  },
]

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-knob-slider',
  templateUrl: './knob-slider.component.html',
  styleUrls: ['./knob-slider.component.scss']
})
export class KnobSliderComponent implements OnInit {
@Input('knobSettings')knobSettings;
  constructor() { }

  ngOnInit(): void {
  }

}

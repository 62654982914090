import { Component, OnInit } from '@angular/core';
import { KnobService } from '@app/core';

@Component({
  selector: 'app-educate-tool',
  templateUrl: './educate-tool.component.html',
  styleUrls: ['./educate-tool.component.scss']
})
export class EducateToolComponent implements OnInit {

  exam;
  sub;
  examType="production";
  constructor(private knobService:KnobService) { }

  ngOnInit(): void {

    this.exam = this.knobService.getExam(this.examType);
    this.sub = this.knobService.onExamChange.subscribe(res=>{
      if(res===this.examType)
      {
        this.exam = this.knobService.getExam(this.examType);
      }

    })
  }

  ngOnDestroy()
  {
    if(this.sub)
    {
      this.sub.unsubscribe();
    }
  }

}

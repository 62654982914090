



<ng-container  *ngFor="let halo of exam.halos; let i=index">

  <h4 class="white">HALO {{i+1}} <span class="btn white" (click)="removeHalo(halo)"><i class="fa fa-trash"></i></span></h4>
  <app-halo-knob [examType]="examType" [halo]="halo"></app-halo-knob>
  <hr class="white"/>
</ng-container>
<div class="row">
  <div class="col-6">
    <button class="btn btn-branding-secondary-square-outline" (click)="addHalo()">Add Halo</button>
  </div>
</div>

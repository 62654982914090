import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core';
import { ConfirmMessageComponent } from '@app/modals';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
// import { Constants } from '@app/app.constants';


@Component({
  selector: 'app-set-dev-mode',
  templateUrl: './set-dev-mode.component.html',
  styleUrls: ['./set-dev-mode.component.scss']
})
export class SetDevModeComponent implements OnInit {

  isDevMode;
  sub;
  confirmModal;
  constructor(private authService:AuthService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.isDevMode = this.authService.getIsDevMode();
    this.sub = this.authService.devModeChanged.subscribe(res=>{
      this.isDevMode = this.authService.getIsDevMode();
    })
  }

  ngOnDestroy()
  {
    if(this.sub)
    {
      this.sub.unsubscribe();
    }
  }

  toggleState()
  {
    this.authService.setIsDevMode(!this.isDevMode);
  }

  openModal()
  {
    let message;
    if(this.isDevMode)
    {
      message='You are entering data collection mode. All data collected will considered officially part of our study.'
    }else{
      message='You are entering dev mode. The data collected will not be officially part of our study.'
    }
    this.confirmModal = this.modalService.open(ConfirmMessageComponent, {windowClass: 'mobile-menu-window3 fade-slide13'})
    this.confirmModal.componentInstance.message = message;
    this.confirmModal.result.then((result) => {
      if(result===true)
      {
        this.toggleState();
        // this.knobService.removeHalo(this.examType, halo);
        // this.dataService.remove(this.serviceName, ptr)
        // .then(res=>{
        //   this._flashMessagesService.show(Constants.flashSuccessMessage, { cssClass: 'alert-success', timeout: Constants.successTimeout });
        // })
        // .catch(err=>{
        //   this._flashMessagesService.show(err, { cssClass: 'alert-danger', timeout: Constants.errorTimeout });
        // })
      }


      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
     // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}

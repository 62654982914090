import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { PwaService } from '@app/core';
import { splashAnimation } from '@app/animations/splash.animation';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  animations: [splashAnimation  ],
  styleUrls: ['./splash.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashComponent implements OnInit {

 show = true;

  constructor(private pwaService: PwaService,
        private cdr: ChangeDetectorRef,
        // private appRef: ApplicationRef
      ) { }

  ngOnInit() {
    this.pwaService.checkForUpdate()
            .subscribe(result => {
                this.show = result;
                this.cdr.detectChanges();
            });
  }

}

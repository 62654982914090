import { Component, Input, OnInit } from '@angular/core';
import { KnobService } from '@app/core';
import { ConfirmDeleteComponent } from '@app/modals';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
// import { Constants } from '@app/app.constants';

@Component({
  selector: 'app-halo-knobs',
  templateUrl: './halo-knobs.component.html',
  styleUrls: ['../halomtr-knobs/halomtr-knobs.component.scss', './halo-knobs.component.scss']
})
export class HaloKnobsComponent implements OnInit {
@Input('examType')examType;
exam;
  sub;
  deleteModal;
  constructor(private knobService:KnobService, private modalService: NgbModal) { }

  ngOnInit(): void {

    this.exam = this.knobService.getExam(this.examType);
    // this.initForm();
    this.sub = this.knobService.onExamChange.subscribe(res=>{
      if(res===this.examType){
        this.exam = this.knobService.getExam(this.examType);
        // this.initForm();
      }
    })

  }

  addHalo()
  {
    this.knobService.addHalo(this.examType);
  }

  removeHalo(halo)
  {

    this.deleteModal = this.modalService.open(ConfirmDeleteComponent, {windowClass: 'mobile-menu-window3 fade-slide13'})
    this.deleteModal.componentInstance.elementName = 'Halo';
    this.deleteModal.result.then((result) => {
      if(result===true)
      {
        this.knobService.removeHalo(this.examType, halo);
        // this.dataService.remove(this.serviceName, ptr)
        // .then(res=>{
        //   this._flashMessagesService.show(Constants.flashSuccessMessage, { cssClass: 'alert-success', timeout: Constants.successTimeout });
        // })
        // .catch(err=>{
        //   this._flashMessagesService.show(err, { cssClass: 'alert-danger', timeout: Constants.errorTimeout });
        // })
      }


      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
     // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  ngOnDestroy()
  {
    if(this.sub)
    {
      this.sub.unsubscribe();
    }
  }

}

import { Component, OnInit, Input, ViewChild, OnChanges, ElementRef } from '@angular/core';
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { fromEvent, Observable, Subscription } from "rxjs";
import { VertexShader, HaloShader, GlareShader } from '@app/shaders';
import { HalomtrService, KnobService } from '@app/core';

@Component({
  selector: 'app-halomtr-screen-overlay-glare',
  templateUrl: './halomtr-screen-overlay-glare.component.html',
  styleUrls: ['../halomtr-screen/halomtr-screen.component.scss', './halomtr-screen-overlay-glare.component.scss']
})
export class HalomtrScreenOverlayGlareComponent implements OnInit, OnChanges {
@Input('bulb')bulb;
@Input('glare')glare;
@Input('camera')camera;
@Input('examType')examType;
@Input('canvasWidth')canvasWidth;
@Input('canvasHeight')canvasHeight;
@Input('imageWidth')imageWidth;
@Input('imageHeight')imageHeight;
@ViewChild('canvasContainer') canvasContainer:ElementRef;


  scene;
  renderer;
  composer;
  subChanges;
  intensity;

  constructor(private halomtrService:HalomtrService, private knobService:KnobService) { }

  ngOnInit(): void {

      this.intensity = this.halomtrService.retrieveKnobValue(this.glare,'intensity');
      this.scene = new THREE.Scene();

      this.renderer = new THREE.WebGLRenderer({ alpha: true });
      // this.renderer.setSize( this.canvasWidth, this.canvasHeight );
      this.animate();

      this.subChanges = this.knobService.onChange.subscribe(res=>{

        if(res.key===this.glare.key)
        {
          this.glare = res;
          // this.animate();
          this.redraw();
          this.animate();
        }
      })

  }

  ngOnChanges(changes)
  {
    console.log('glare overlay changes! ', changes)
    if(this.renderer){
       this.render()
    }
  }

  ngAfterViewInit()
  {
    this.render()
  }

  addElements()
  {
    this.addGlare();
  }

  addGlare()
  {
      let rad = this.halomtrService.retrieveKnobValue(this.glare,'radius');
      this.intensity = this.halomtrService.retrieveKnobValue(this.glare,'intensity');
      let thickness = this.halomtrService.retrieveKnobValue(this.glare,'thickness');
      let tmp = Math.ceil(this.canvasWidth*rad);

      let geometry = new THREE.PlaneGeometry( tmp, tmp );
      let color = this.bulb.color;
      let position = this.halomtrService.getBulbPosition(this.bulb, this.canvasWidth, this.canvasHeight);

      let uniforms={
        radius:{value: 0.5},
        aspectRatio:{value:1.0},
        thickness: {value: thickness},
        intensity:{value:this.intensity},
        base: {value:new THREE.Vector2( 0.5, 0.5 )},//position,//new Uniform( new Vector2(position.x, position.y) ) ,
        color:  {value:new THREE.Vector4( color[0], color[1], color[2], 1.0 )}
      }
      let material =  new THREE.ShaderMaterial({
         uniforms: uniforms,
         fragmentShader: GlareShader,
         vertexShader: VertexShader,
       })

      let glare = new THREE.Mesh( geometry, material );


      glare.position.setX(position.x);
      glare.position.setY(position.y);


      this.scene.add( glare );
      this.scene.updateMatrix()


  }

  render()
  {
    console.log('render');
    this.resizeCanvas();
    this.canvasContainer.nativeElement.append( this.renderer.domElement);
     // this.screenContainer.nativeElement.insertBefore( this.renderer.domElement, this.screenContainer.nativeElement.firstChild );
     // this.renderer.render( this.scene, this.camera );

     this.animate();
  }


  animate() {


      // this.renderer.render( this.scene, this.camera );


     //https://stackoverflow.com/questions/15354117/three-js-blur-the-frame-buffer
     this.composer = new EffectComposer( this.renderer );
     this.composer.addPass( new RenderPass( this.scene, this.camera ) );

     let tdiff = 0.0;
     let vBlur = 2.0 / 512.0;
     // let horz = HorizontalBlurShader;
     // horz.uniforms.tDiffuse.value = tdiff
     // horz.uniforms.h.value = vBlur;
     //
     // let hblur = new ShaderPass( horz );
     // this.composer.addPass( hblur );
     //
     // let vert = VerticalBlurShader;
     // vert.uniforms.tDiffuse.value = tdiff
     // vert.uniforms.v.value = vBlur
     // // vert.uniforms = {
     // //   "tDiffuse":  new THREE.Uniform( new THREE.Number(20.0) ),
     // // 	"v": new THREE.Uniform( new THREE.Number(1.0 / 512.0) ),//{ value: 1.0 / 512.0 }
     // // }
     // let vblur = new ShaderPass( vert );
     // // set this shader pass to render to screen so we can see the effects
     // vblur.renderToScreen = true;
     // this.composer.addPass( vblur );


     this.halomtrService.addBlurEffect(this.composer, tdiff, vBlur, 16)
     this.composer.render();


   }


   resizeCanvas()
   {

     // https://stackoverflow.com/questions/32189831/three-js-scene-not-rendering
     // let bond = this.screenContainer.nativeElement.getBoundingClientRect();
     // let aspectRatio = this.imageWidth/ this.imageHeight;
     // let newW = Math.ceil(bond.height * aspectRatio);
     // let newH = Math.ceil(bond.height);
     //
     // this.canvasWidth = newW;
     // this.canvasHeight = newH;
     // this.camera.left = -1* newW/2;
     // this.camera.right = newW/2;
     // this.camera.top = newH/2;
     // this.camera.bottom = -1 * newH/2;
     this.renderer.setSize( this.canvasWidth, this.canvasHeight );
     // this.camera.updateProjectionMatrix();

     this.redraw();

   }

   redraw()
   {
     this.scene.remove.apply(this.scene, this.scene.children);
     this.addElements()
   }

   ngOnDestroy()
   {
     if(this.subChanges)
     {
       this.subChanges.unsubscribe();
     }
   }

}

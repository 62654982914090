
<div class="page-layout-container" [ngClass]="type">

  <div class="half-page logo-page">
    <div class="centered-content">
      <div class='page-type' [ngClass]="type">{{type}}</div>
      <div class='page-description' *ngIf="description">{{description}}</div>
      <img class="logo" src="/assets/logo/logo.png"/>

    </div>
  </div>
  <div class="half-page" [ngClass]="type">
    <div class="centered-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>

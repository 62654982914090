

export const HaloShader = `
  precision highp float;
  uniform float radius;
  uniform float aspectRatio;
  uniform float thickness;
  uniform vec2 base;
   uniform vec4 color;
  varying vec2 vUv;

  void main()
  {

       float normalizedX = (vUv.x - base.x )*aspectRatio ;
       float normalizedY = vUv.y - base.y;

      // float normalizedX = vUv.x;
      // float normalizedY = vUv.y ;


      if (sqrt(normalizedX * normalizedX + normalizedY * normalizedY) < (radius+thickness) &&  sqrt(normalizedX * normalizedX + normalizedY * normalizedY) > (radius-thickness)) {
      //if (sqrt(normalizedX * normalizedX + normalizedY * normalizedY) < (thickness) &&  sqrt(normalizedX * normalizedX + normalizedY * normalizedY) > (thickness)) {
        gl_FragColor = color;
        // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
      } else {
        // gl_FragColor = vec4(1.0, 1.0, 0.0, 1.0);
        gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
      }


      // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);

  }`;

<div class="form-container">
  <div class="form-container-2">
    <!-- patientForm: {{patientForm.value | json }} -->
    <div class="label" *ngIf="label">{{ label }}</div>
    <app-number-parameter
      [label]="'Age'"
      [parameterFormGroup]="patientForm.get('age')"
      [showErrors]="showErrors"
    ></app-number-parameter>

    <app-text-parameter
      [label]="'Internal Identifier'"
      [parameterFormGroup]="patientForm.get('internalID')"
      [showErrors]="showErrors"
    ></app-text-parameter>

    <app-flat-select-parameter
      [label]="'Eye'"
      [parameterFormGroup]="patientForm.get('selectedEye')"
      [options]="['OS', 'OD']"
      [showErrors]="showErrors"
    ></app-flat-select-parameter>
    <app-iol-select-parameter
      [overallFormGroup]="patientForm"
      [showErrors]="showErrors"
    ></app-iol-select-parameter>

    <app-text-parameter
      [label]="'IOL Code'"
      [parameterFormGroup]="patientForm.get('iolCode')"
      [showErrors]="showErrors"
    ></app-text-parameter>
    <app-number-select-parameter
      [label]="'Lens Power'"
      [min]="0.0"
      [max]="40.0"
      [increment]="0.5"
      [init]="20.0"
      [parameterFormGroup]="patientForm.get('lensPower')"
      [showErrors]="showErrors"
    ></app-number-select-parameter>
    <app-number-select-parameter
      [label]="'Undilated Scotopic Pupil Dilation'"
      [min]="1.0"
      [max]="10.0"
      [increment]="1.0"
      [init]="1.0"
      [parameterFormGroup]="patientForm.get('pupilDilation')"
      [showErrors]="showErrors"
    ></app-number-select-parameter>
    <app-number-select-parameter
      [label]="'Pre-op Refraction'"
      [parameterFormGroup]="patientForm.get('preOpRefraction')"
      [min]="-20.0"
      [max]="20.0"
      [increment]="0.25"
      [init]="0.0"
      [showErrors]="showErrors"
    ></app-number-select-parameter>
    <br />
    <button class="btn btn-large btn-dark-square" (click)="next()">
      Continue
    </button>
    <br /><br />
    <button class="btn btn-large btn-dark-square-outline" (click)="back()">
      <i class="fa fa-arrow-left"></i> Back
    </button>
  </div>
</div>

<!-- this.patientForm.valid: {{ this.patientForm.valid }}
<br />
this.patientForm.value: {{ this.patientForm.value | json }} -->

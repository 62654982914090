
<ng-container *ngIf="!isLoggedIn">
  <app-login></app-login>
</ng-container>

<ng-container *ngIf="isLoggedIn">
  <app-display-dev-mode-warning></app-display-dev-mode-warning>
  <router-outlet></router-outlet>
</ng-container>


<app-body-loading-animation></app-body-loading-animation>





<div class="page-layout-container">
<app-navbar></app-navbar>

<div class="history-page-container container">

  <!-- history: {{history | json}} -->


  <div class="history-content row">
    <div class='col-12'>
    <div class="row history-item" *ngFor="let item of history">
      <div class="col-4">
        <app-history-status [status]="item.status"></app-history-status>
        {{item.type}}
      </div>

      <div class="col-4">
        <span *ngIf="item.patient && item.patient.prettyId">{{item.patient.prettyId}}</span>
      </div>
      <div class="col-4">
        {{item.createdAt | amDateFormat:'LL'}}
      </div>
    </div>
    </div>
    </div>
    <div class="row">
      <div class="col-12">
        <br/><br/><br/>
        <button class="btn btn-branding-square-outline" (click)='saveAllPending()'>Save All Pending</button>
      </div>
    </div>

  </div>

</div>

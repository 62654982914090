import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-history-status',
  templateUrl: './history-status.component.html',
  styleUrls: ['./history-status.component.scss']
})
export class HistoryStatusComponent implements OnInit {
@Input('status')status;
  constructor() { }

  ngOnInit(): void {
  }

}

export const VertexShader = `
    precision lowp float;
    // precision highp float;
    varying vec2 vUv;
    void main() {
      vUv = uv;
      // uv = position;
      // uv_out = uv;

      vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
      gl_Position = projectionMatrix * modelViewPosition;
    }
  `



<app-navbar [style]="'assess'"></app-navbar>

<app-page-layout [type]="'assess'" [description]="description">

  <div class="form-container">
    <app-patient-form [patientForm]="patientForm" [label]="'PLEASE FILL IN PATIENT INFORMATION'" (onNext)="next()" (onBack)="back()"></app-patient-form>
  </div>
</app-page-layout>

<!-- part one



// retrieve patient or new

part two
//age
 Eye (OD / OS)
 IOL Manufacturer
 IOl Model
 Lens Power
 UNdilitated scotopic puplil dilation (mm)
 pre-op refraction

Part three
   is the patient experiencing Aberrations?

Part four
  editor -->

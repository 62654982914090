



<div class="single-knobs-container row">
  <div class="col-12">

    <h4>Radius</h4>
    <app-knob *ngIf="halo.radius" [knob]="halo.radius" (onChange)="changeRadius($event)"></app-knob>
  </div>
  <div class="col-12">
    <h4>Intensity</h4>
    <app-knob *ngIf="halo.intensity" [knob]="halo.intensity" (onChange)="changeIntensity($event)"></app-knob>
  </div>

  <div class="col-12">
    <h4>Thickness</h4>
    <app-knob *ngIf="halo.thickness" [knob]="halo.thickness" (onChange)="changeThickness($event)"></app-knob>
  </div>
</div>

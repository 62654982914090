
import { CounselPatientFormComponent } from './counsel-patient-form/counsel-patient-form.component';
import { CounselHalomtrComponent } from './counsel-halomtr/counsel-halomtr.component';

export const CounselRoutes = [
  {
    path: "counsel", component: CounselPatientFormComponent,
  },
  {
    path: "counsel-halomtr", component: CounselHalomtrComponent,
  }
]

import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { FeathersService } from './feathers.service';
import { isNil } from 'lodash';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class AuthService {
@Output('currentUserChanged') currentUserChanged = new EventEmitter(true);
@Output('devModeChanged') devModeChanged = new EventEmitter(true);

  isDevMode=true;
  constructor(private feathers: FeathersService) {

    this.feathers.currentUserChanged.subscribe(res=>{
      this.currentUserChanged.emit(res);
    })
  }

  logIn(credentials?): Promise<any> {
    return this.feathers.authenticate(credentials)
    .then(res=>{

      return res;
    })
    .catch(error=>{

      throw error;
    });
  }


  logOut() {
    this.feathers.logout();
  };

  isLoggedIn(){
    var tmp = this.feathers.getUser();

    if(tmp===null)
    {
      return false;
    }else{
      return true;
    }

  }

  isAdmin(){
    return this.isRole('admin');
  }
  isAmbassador(){
    return this.isRole('ambassador');
  }
  isResto(){
    return this.isRole('resto');
  }
  isCustomer(){
    return this.isRole('customer');
  }
  isRole(role){
    var user = this.feathers.getUser();

    if(user===null)
    {
      return false;
    }else{
      if(typeof user.role!='undefined' && user.role!=null && user.role===role)
      {
        return true;
      }else{
        return false
      }

    }
  }
  getRole(){
    var user = this.feathers.getUser();

    if(user===null)
    {
      return null;
    }else{
      if(typeof user.role!='undefined' && user.role!=null)
      return user.role;
    }
  }

  getUser()
  {
    var currentUser = this.feathers.getUser();
    if(typeof currentUser === 'undefined')
    {
      return null;
    }else{
        return currentUser;
    }
  }

  getClinicId()
  {
    let user = this.getUser();
    if(!isNil(user) && !isNil(user.clinicId))
    {
      return user.clinicId;
    }
    return null;
  }

  getIsDevMode()
  {
    return this.isDevMode;
  }
  setIsDevMode(val)
  {
    this.isDevMode=val;
    this.devModeChanged.emit(this.isDevMode);
  }
}

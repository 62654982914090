import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/core';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss']
})
export class LogoutButtonComponent implements OnInit {
@Input('style')style='counsel';

  constructor(private authService:AuthService) { }

  ngOnInit(): void {
  }

  logout()
  {
    this.authService.logOut();
  }

}

import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { DataService, AuthService } from "@app/core";
import { isNil } from "lodash";
import "rxjs/add/operator/map";

@Component({
  selector: "app-retrieve-patient",
  templateUrl: "./retrieve-patient.component.html",
  styleUrls: ["./retrieve-patient.component.scss"],
})
export class RetrievePatientComponent implements OnInit {
  @Output("onSelect") onSelect = new EventEmitter(true);
  searchString;
  serviceName = "patients";
  query;
  clinicId;
  selected;
  constructor(
    private dataService: DataService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.clinicId = this.authService.getClinicId();
    this.authService.currentUserChanged.subscribe((res) => {
      this.clinicId = this.authService.getClinicId();
    });
  }

  search() {
    console.log("searchString: ", this.searchString);
    console.log("clinicId: ", this.clinicId);
    if (
      this.searchString &&
      this.searchString !== "" &&
      !isNil(this.clinicId)
    ) {
      this.dataService
        .dataNever(this.serviceName, {
          clinicId: this.clinicId,
          prettyId: this.searchString,
          $limit: 1,
        })
        .map((m) => {
          return m.data[0];
        })
        .subscribe((res) => {
          this.select(res);
        });
    }
  }

  select(user) {
    this.selected = user;
    this.onSelect.emit(this.selected);
  }
}

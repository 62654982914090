import { Component, Input, OnInit } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss']
})
export class LoadingAnimationComponent implements OnInit {

@Input('isLoading')  isLoading=false;
@Input('config') config = { animationType: ngxLoadingAnimationTypes.rotatingPlane, backdropBackgroundColour: 'rgba(0,0,0,0.3)', backdropBorderRadius: '10px', primaryColour: '#4df2ac', secondaryColour: '#4df2ac', tertiaryColour: '#ffffff', fullScreenBackdrop:true };

  constructor() { }

  ngOnInit() {

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HalomtrToolModule } from '@app/halomtr-tool/halomtr-tool.module';
import { SharedModule } from '@app/shared/shared.module';
import { PatientModule } from '@app/patient/patient.module';
import { AssessFormComponent } from './assess-form/assess-form.component';
import { AssessPartOneComponent } from './assess-part-one/assess-part-one.component';
import { AssessPartTwoComponent } from './assess-part-two/assess-part-two.component';
import { AssessHalomtrComponent } from './assess-halomtr/assess-halomtr.component';
import { AssessPartThreeComponent } from './assess-part-three/assess-part-three.component';



@NgModule({
  declarations: [AssessFormComponent, AssessPartOneComponent, AssessPartTwoComponent, AssessHalomtrComponent, AssessPartThreeComponent],
  imports: [
    CommonModule,
    PatientModule,
    HalomtrToolModule,
    SharedModule
  ]
})
export class AssessModule { }

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss']
})
export class PatientFormComponent implements OnInit {
@Input('patientForm')patientForm;
@Input('label')label;
@Output('onNext')onNext = new EventEmitter(true);
@Output('onBack')onBack = new EventEmitter(true);

  showErrors=false;
  constructor() { }

  ngOnInit(): void {
  }

  next()
  {
    // this.showErrors=true;
    if(this.patientForm.valid)
    {
      this.onNext.emit(true);
    }else{
      this.showErrors=true;
    }

  }
  back()
  {
    this.onBack.emit(true);
  }

}


import { MainComponent } from './main/main.component';
import { HistoryComponent } from './history/history.component';

export const MainRoutes = [
  {
    path: "", component: MainComponent,
  },
  {
    path: "history", component: HistoryComponent,
  }
]


<div class="page-container">

  <div class="left-page-container">
    <app-halomtr-screen [exam]="exam" [halos]="halos" [blurs]="blurs" [stars]="stars" [examType]="examType"></app-halomtr-screen>
  </div>
  <div class="right-page-container">
    <app-halomtr-knobs  [exam]="exam" [examType]="examType"></app-halomtr-knobs>
    <div class="save-container" *ngIf="canSave">
      <button class="btn btn-large btn-branding-secondary-square-outline" (click)='save()'>Save</button>
    </div>
  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-flat-select-parameter',
  templateUrl: './flat-select-parameter.component.html',
  styleUrls: ['./flat-select-parameter.component.scss']
})
export class FlatSelectParameterComponent implements OnInit {
@Input('parameterFormGroup')parameterFormGroup;
@Input('label')label;
@Input('options')options;
@Input('showErrors')showErrors=false;
@Input('isValid')isValid=true;
@Output('onChange')onChange = new EventEmitter(true);
  constructor() { }

  ngOnInit(): void {
  }

  select(option)
  {
    if(this.parameterFormGroup.value !== option)
    {
      this.parameterFormGroup.setValue(option);
      this.onChange.emit(option);
    }

  }

}

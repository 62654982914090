
<!-- selected: {{ selected }} -->
<!-- <button (click)="up()">Up</button> -->

<!-- <div #roll> -->
  <!-- drag-scroll -->
<div  #roll class="roll" [ngClass]="isGoingUp?'up':''" [ngClass]="isGoingDown?'down':''"   (indexChanged)="indexChanged($event)" (panstart)="panstart($event)" (panend)="panend($event)"   (pan)="pan($event)" (drag)="drag($event)" (dragEnd)="dragEnd($event)" (swipeup)="swipeUp($event)" (swipedown)="swipeDown($event)" (tap)="tap($event)" (pinch)="pinch($event)" (scroll)="onScroll($event)">


  <!-- <div class="roll-item" ><span class="txt">{{getItem(-2)}}</span></div>
  <div class="roll-item" ><span class="txt">{{getItem(-1)}}</span></div>
  <div class="roll-item selected" ><span class="txt">{{getItem(0)}}</span></div>
  <div class="roll-item" ><span class="txt">{{getItem(1)}}</span></div>
  <div class="roll-item" ><span class="txt">{{getItem(3)}}</span></div> -->
  <div class="roll-item">&nbsp;</div>
  <div class="roll-item">&nbsp;</div>
  <div class="roll-item" *ngFor="let item of options" [ngClass]="item === selected?'selected':''" (click)="setItem(item)">{{item}}</div>
  <div class="roll-item">&nbsp;</div>
  <div class="roll-item">&nbsp;</div>
  <div class="overlay-indicator">&nbsp;</div>
<!-- </drag-scroll> -->
</div>
<!-- <button (click)="down()">Down</button> -->

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-halomtr-screen-overlay-bulb',
  templateUrl: './halomtr-screen-overlay-bulb.component.html',
  styleUrls: ['./halomtr-screen-overlay-bulb.component.scss']
})
export class HalomtrScreenOverlayBulbComponent implements OnInit {
@Input('bulb')bulb;
@Input('exam')exam;
@Input('examType')examType;
@Input('camera')camera;
@Input('canvasWidth')canvasWidth;
@Input('canvasHeight')canvasHeight;
@Input('imageWidth')imageWidth;
@Input('imageHeight')imageHeight;

  constructor() { }

  ngOnInit(): void {

  }

}

import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-text-parameter",
  templateUrl: "./text-parameter.component.html",
  styleUrls: ["./text-parameter.component.scss"],
})
export class TextParameterComponent implements OnInit {
  @Input("parameterFormGroup") parameterFormGroup;
  @Input("label") label;
  @Input("showErrors") showErrors = false;
  @Output("onChange") onChange = new EventEmitter(true);

  constructor() {}

  ngOnInit(): void {}
  valueChanged() {
    this.onChange.emit(this.parameterFormGroup.value);
  }
}

import { NgModule } from '@angular/core';
import { NgxLoadingModule } from 'ngx-loading';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes }  from '@angular/router';
import { SplashComponent } from './splash/splash.component';
import { LoadingAnimationComponent } from './loading-animation/loading-animation.component';
import { BodyLoadingAnimationComponent } from './body-loading-animation/body-loading-animation.component';
import { LogoutButtonComponent } from './logout-button/logout-button.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { HistoryStatusComponent } from './history-status/history-status.component';
import { SetDevModeComponent } from './set-dev-mode/set-dev-mode.component';
import { DisplayDevModeWarningComponent } from './display-dev-mode-warning/display-dev-mode-warning.component';


@NgModule({
  declarations: [SplashComponent, LoadingAnimationComponent, BodyLoadingAnimationComponent, LogoutButtonComponent, NavbarComponent, PageLayoutComponent, HistoryStatusComponent, SetDevModeComponent, DisplayDevModeWarningComponent,],
  imports: [
    CommonModule,
    RouterModule,
    NgxLoadingModule
  ],
  exports: [LoadingAnimationComponent, BodyLoadingAnimationComponent, LogoutButtonComponent, NavbarComponent, PageLayoutComponent, HistoryStatusComponent, SetDevModeComponent, DisplayDevModeWarningComponent]
})
export class SharedModule { }

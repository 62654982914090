import { Component, Input, OnInit, HostListener, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { isNil } from 'lodash';

@Component({
  selector: 'app-roll',
  templateUrl: './roll.component.html',
  styleUrls: ['./roll.component.scss']
})
export class RollComponent implements OnInit {
@Input('min')min=0.0;
@Input('max')max=1.0;
@Input('init')init=0.6;
@Input('precision')precision=2;
@Input('increment')increment=0.1;
@Output('onChange')onChange = new EventEmitter(true);
@HostListener('window:scroll', ['$event']) // for window scroll events
@HostListener('window:scrollend', ['$event']) // for window scroll events
@ViewChild('roll') rollRef:ElementRef;

  options = [];
  selected;
  isGoingUp=false;
  isGoingDown=false;
  translateY=0;
  timeout;
  isScrolling=false;
  itemHeight=50;
  constructor() { }

  ngOnInit(): void {

    let tmpInit = this.init;

    for(let i=this.min; i<=this.max; i=i+this.increment)
    {

      let newVal = i;//i.toPrecision(this.precision)
      if(i<=tmpInit)
      {
        this.selected=newVal;
      }
      this.options.push(newVal);
    }


  }

  ngAfterViewInit()
  {
    // this.setOffset();
  }

  getIndex(val)
  {
    if(val<=this.min)
    {
      return 0;
    }
    if(val>=this.max)
    {
      return this.options.length-1;
    }
    let tmp = val - this.min;
    let tmp2 = (tmp/this.increment);
    let index = Math.round(tmp2);
    return index;
  }

  getItem(offset)
  {
    let selectedIndex = this.getIndex(this.selected);
    let newIndex = selectedIndex+offset;
    if(newIndex<0 || newIndex>=this.options.length)
    {
      return "-";
    }
    return this.options[newIndex];
  }

 up()
  {
    this.isGoingUp=true;
    this.timeoutMB(50).then(res=>{
      let selectedIndex = this.getIndex(this.selected);
      if(selectedIndex>0)
      {
        this.selected =this.options[selectedIndex-1]
      }
      this.isGoingUp=false;
    })
  }

  timeoutMB(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  down()
  {

    this.isGoingDown=true;
    this.timeoutMB(50).then(res=>{
      let selectedIndex = this.getIndex(this.selected);
      if(selectedIndex<this.options.length-1)
      {
        this.selected = this.options[selectedIndex+1]
      }
      this.isGoingDown=false;
    })


  }

  swipeUp(ev)
  {
    // ev.velocityY:
    // ev.deltaY

    let top = this.rollRef.nativeElement.scrollTop;
    this.rollRef.nativeElement.scrollTop = top+ ev.deltaY *0.2;
  }
  swipeDown(ev)
  {
    let top = this.rollRef.nativeElement.scrollTop;
   this.rollRef.nativeElement.scrollTop = top - ev.deltaY *0.2;
  }


  pinch(ev)
  {
  }

  tap(ev)
  {
  }

  pan(ev)
  {


    let top = this.rollRef.nativeElement.scrollTop;
   this.rollRef.nativeElement.scrollTop = top - ev.deltaY *0.1;

  }

  panstart(ev)
  {

  }

  panend(ev)
  {

  }

  panup(ev)
  {

  }

  pandown(ev)
  {

  }


  drag(ev)
  {

  }

  dragEnd(ev)
  {

  }

  indexChanged(ev)
  {

  }

  onScroll(event) {

    this.isScrolling = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.isScrolling = false;
      this.onScrollEnd()
    }, 300);
  }

  onScrollEnd() {


    let offset = this.rollRef.nativeElement.scrollTop;


    let index = Math.round(offset/this.itemHeight);

    if(index<0)
    {
      // this.selected = this.options[0];
      index =0 ;
    }
    if(index>=this.options.length)
    {
      // this.selected = this.options[this.options.length-1];
      index =this.options.length-1 ;
    }
    this.selected = this.options[index];
    this.onChange.emit(this.selected);
    this.setOffset();



  }

  setOffset()
  {
    let index = this.getIndex(this.selected);
    this.rollRef.nativeElement.scrollTop = (index)*this.itemHeight;
  }

  setItem(item)
  {
    this.selected = item;
    this.onChange.emit(this.selected);
    this.setOffset();
  }



}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-halomtr-editor',
  templateUrl: './halomtr-editor.component.html',
  styleUrls: ['./halomtr-editor.component.scss']
})
export class HalomtrEditorComponent implements OnInit {
@Input('exam')exam;
@Input('canSave')canSave=false;
@Input('examType')examType="production";
@Output('onSave')onSave= new EventEmitter(true);
// @Input('type')type='ASSESS';  // ASSESS | EDUCATE

  blurs;
  halos;
  stars;
  constructor() { }

  ngOnInit(): void {

  }

  save()
  {
    this.onSave.emit(true);
  }

}

import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Http, Headers} from '@angular/http';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { MainModule } from './main/main.module';
import { EducateModule } from './educate/educate.module';
import { HalomtrToolModule } from './halomtr-tool/halomtr-tool.module';
import { AssessModule } from './assess/assess.module';
import { CounselModule } from './counsel/counsel.module';
import { ModalsModule } from './modals/modals.module';
import { MODALS_COMPONENTS } from '@app/modals/entry-components';
import { PatientModule } from './patient/patient.module';
import { InputModule } from './input/input.module';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

export class MyHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
      // override hammerjs default configuration
      'swipe': { direction: Hammer.DIRECTION_ALL  }
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HammerModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AuthModule,
    CoreModule,
    SharedModule,
    MainModule,
    EducateModule,
    HalomtrToolModule,
    AssessModule,
    CounselModule,
    ModalsModule,
    PatientModule,
    InputModule
  ],
  providers: [ {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }],
  bootstrap: [AppComponent],
  entryComponents: [...MODALS_COMPONENTS],
})
export class AppModule { }

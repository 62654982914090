<div class="modal-header">
  <h4 class="modal-title">Delete?</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  {{elementName}}




</div>
<div class="modal-footer">
  <!-- <button class="btn btn-branding" (click)="create()">Créer</button> -->
  <button type="button" class="btn btn-danger " (click)="closeModal(true)">Confirm</button>
  <button type="button" class="btn btn-outline-dark" (click)="closeModal(false)">Close</button>

</div>

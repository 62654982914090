
// http://jasonwatmore.com/post/2017/04/19/angular-2-4-router-animation-tutorial-example
// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style } from '@angular/animations';

export const modalEnterAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    trigger('modalEnterAnimation', [

        // // route 'enter' transition
        // transition(':enter', [
        //
        //     // css styles at start of transition
        //     style({ opacity: 0 }),
        //
        //     // animation and styles at end of transition
        //     animate('.3s', style({ opacity: 1 }))
        // ]),
        transition('void => *', [
          style({ transform: 'scale3d(.3, .3, .3)' }),
          animate(200)
        ]),
        transition('* => void', [
          animate(200, style({ transform: 'scale3d(.0, .0, .0)' }))
        ])
    ]);


<div class="single-knobs-container row">
  <div class="col-12">
    <h4>Radius</h4>
    <app-knob *ngIf="exam.glares.radius" [knob]="exam.glares.radius" (onChange)="changeRadius($event)"></app-knob>
  </div>
  <div class="col-12">
    <h4>Intensity</h4>
    <app-knob *ngIf="exam.glares.intensity" [knob]="exam.glares.intensity" (onChange)="changeIntensity($event)"></app-knob>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { KnobService, FormsService } from '@app/core';
import { isNil } from 'lodash';

@Component({
  selector: 'app-halo-knob',
  templateUrl: './halo-knob.component.html',
  styleUrls: ['../halomtr-knobs/halomtr-knobs.component.scss', './halo-knob.component.scss']
})
export class HaloKnobComponent implements OnInit {
@Input('examType')examType;
@Input('halo')halo;

  exam;
  sub;
  key;
  constructor(private knobService:KnobService, private formsService:FormsService) { }


  ngOnInit(): void {
    this.exam = this.knobService.getExam(this.examType);
    this.initForm();
    this.sub = this.knobService.onExamChange.subscribe(res=>{
      if(res===this.examType){
        this.exam = this.knobService.getExam(this.examType);
        this.initForm();
      }
    })
  }

  initForm()
  {

    if(!isNil(this.halo) && !isNil(this.halo.key))
    {
      this.key = this.halo.key;
    }
  }

  changeRadius(val)
  {
    this.knobService.updateValue(this.examType, 'halos', this.key, 'radius', val);
  }

  changeIntensity(val)
  {
    this.knobService.updateValue(this.examType, 'halos', this.key, 'intensity', val);
  }

  changeThickness(val)
  {
    this.knobService.updateValue(this.examType, 'halos', this.key, 'thickness', val);
  }




  ngOnDestroy()
  {
    if(this.sub)
    {
      this.sub.unsubscribe();
    }

  }


}

import { Component, OnInit } from '@angular/core';
import { HistoryService } from '@app/core';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  history;
  constructor(private historyService:HistoryService) { }

  ngOnInit(): void {
    this.history = this.historyService.getHistory();
  }

  saveAllPending()
  {
    this.historyService.saveAllPending().then(res=>{

    });
  }


}

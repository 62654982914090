import { Injectable, Output, EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class BodyLoadingAnimationService {
@Output('loadingChanged') loadingChanged = new EventEmitter(true);

  isLoading = false;
  constructor() { }

   getIsLoading()
   {
     return  this.isLoading;
   }



   setIsLoading(val)
   {

     this.isLoading = val;
     this.loadingChanged.emit(val);
   }
}

import { Component, Input, OnInit } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { modalEnterAnimation } from '@app/animations/modal-enter.animation';

@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.component.html',
  animations: [modalEnterAnimation],
  host: { '[@modalEnterAnimation]': '' },
  styleUrls: ['./confirm-message.component.scss']
})
export class ConfirmMessageComponent implements OnInit {
@Input('message')message;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeModal(state)
  {
    this.activeModal.close(state);
  }

}

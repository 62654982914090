

<ng-container>
  <app-halomtr-screen-overlay-glare [glare]="exam.glares"
                                    [bulb]="bulb"
                                    [examType]='examType'
                                    [canvasHeight]="canvasHeight"
                                    [canvasWidth]="canvasWidth"
                                    [imageHeight]='imageHeight'
                                    [imageWidth]='imageWidth'
                                    [camera]="camera">
                                </app-halomtr-screen-overlay-glare>

<ng-container *ngFor='let halo of exam.halos'>
  <app-halomtr-screen-overlay-halo [halo]="halo"
                                    [bulb]="bulb"
                                    [examType]='examType'
                                    [canvasHeight]="canvasHeight"
                                    [canvasWidth]="canvasWidth"
                                    [imageHeight]='imageHeight'
                                    [imageWidth]='imageWidth'
                                    [camera]="camera">
                                </app-halomtr-screen-overlay-halo>
</ng-container>

  <app-halomtr-screen-overlay-star [star]="exam.starbursts"
                                    [bulb]="bulb"
                                    [examType]='examType'
                                    [canvasHeight]="canvasHeight"
                                    [canvasWidth]="canvasWidth"
                                    [imageHeight]='imageHeight'
                                    [imageWidth]='imageWidth'
                                    [camera]="camera">
                                </app-halomtr-screen-overlay-star>

</ng-container>

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-number-parameter',
  templateUrl: './number-parameter.component.html',
  styleUrls: ['./number-parameter.component.scss']
})
export class NumberParameterComponent implements OnInit {
@Input('parameterFormGroup')parameterFormGroup;
@Input('label')label;
@Input('showErrors')showErrors=false;
@Output('onChange')onChange = new EventEmitter(true);
  constructor() { }

  ngOnInit(): void {
  }

  valueChanged()
  {
    this.onChange.emit(this.parameterFormGroup.value);
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KnobService, AssessService } from '@app/core';

@Component({
  selector: 'app-assess-halomtr',
  templateUrl: './assess-halomtr.component.html',
  styleUrls: ['./assess-halomtr.component.scss']
})
export class AssessHalomtrComponent implements OnInit {

  exam;
  sub;
  examType="production";
  constructor(private knobService:KnobService, private assessService:AssessService, private router:Router) { }

  ngOnInit(): void {
    this.exam = this.knobService.getExam(this.examType);
    this.sub = this.knobService.onExamChange.subscribe(res=>{
      if(res===this.examType)
      {
        this.exam = this.knobService.getExam(this.examType);
      }

    })
  }

  save()
  {
    let exam = this.knobService.getExam(this.examType);
    let assessment = {
      halos: exam.halos,
      glares: exam.glares,
      starbursts: exam.starbursts,
      examId: exam.id
    }
    this.assessService.addDataToSubmitQueue(assessment);
    this.router.navigate(['/history'], { });
  }

  ngOnDestroy()
  {
    if(this.sub)
    {
      this.sub.unsubscribe();
    }
  }

}




<div class="input-container" [ngClass]="(showErrors && !isValid)?'err':''">
  <div class="label-container" >
    {{label}}
  </div>
  <div class="content-container">
    <ng-content></ng-content>
  </div>
</div>

import { Injectable } from '@angular/core';
import { FeathersService } from './feathers.service';
import { Observable } from "rxjs";
import { map  } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private feathers: FeathersService) { }

  data$(serviceName, searchQuery):Observable<any> {

      if(typeof searchQuery ==='undefined' || searchQuery ===null)
      {
        searchQuery = {
          $limit: 30,

        }
      }
      return this.feathers
        .service(serviceName)
        .watch({listStrategy: 'always'})
        .find({
          query: searchQuery
        });
  }

  get(serviceName, id)
  {
    return this.feathers
      .service(serviceName)
      .watch({listStrategy: 'never'})
      .get(id);
  }

  dataNever(serviceName, searchQuery) {


    if(typeof searchQuery ==='undefined' || searchQuery ===null)
    {
      searchQuery = {
        $limit: 30,

      }
    }
    return this.feathers
      .service(serviceName)
      .watch({listStrategy: 'never'})
      .find({
        query: searchQuery
      });
  }




  patch(serviceName, item)
  {



    if(typeof item.id !='undefined'
      && item.id !=null
    )
    {
      return  this.feathers
        .service(serviceName)
        .patch(
          item.id,
          item,
          {id:item.id},
        );
    }


  }

  create(serviceName, item, query?)
  {

    let qq = {};
    if(query!='undefined' && query!=null)
    {
      qq = query
    }
      return  this.feathers
        .service(serviceName)
          .create(  item, {query:qq} );



  }

  remove(serviceName, item)
  {


    if(typeof item.id !='undefined'
      && item.id !=null
    )
    {
    return  this.feathers
      .service(serviceName)
      .remove(  item.id );
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core';

@Component({
  selector: 'app-display-dev-mode-warning',
  templateUrl: './display-dev-mode-warning.component.html',
  styleUrls: ['./display-dev-mode-warning.component.scss']
})
export class DisplayDevModeWarningComponent implements OnInit {

  isDevMode;
  sub;
  constructor(private authService:AuthService) { }

  ngOnInit(): void {
    this.isDevMode = this.authService.getIsDevMode();
    this.sub = this.authService.devModeChanged.subscribe(res=>{
      this.isDevMode = this.authService.getIsDevMode();
    })
  }

  ngOnDestroy()
  {
    if(this.sub)
    {
      this.sub.unsubscribe();
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { AssessService } from '@app/core';
import { Router } from '@angular/router';
import { Constants } from '@app/app.constants';

@Component({
  selector: 'app-assess-part-two',
  templateUrl: './assess-part-two.component.html',
  styleUrls: ['./assess-part-two.component.scss']
})
export class AssessPartTwoComponent implements OnInit {

  description;
  patientForm;
  constructor(private assessService:AssessService, private router:Router) { }

  ngOnInit(): void {
    this.description=  Constants.assessDescription;
    this.patientForm = this.assessService.getPatientForm();
  }

  next()
  {
    this.router.navigate(['/assess-part-three'], { });
  }

  back()
  {
    this.router.navigate(['/assess'], { });
  }

}

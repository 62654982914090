import { Component, OnInit } from '@angular/core';
import { AssessService } from '@app/core';
import { Router } from '@angular/router';
import { Constants } from '@app/app.constants';


@Component({
  selector: 'app-assess-part-three',
  templateUrl: './assess-part-three.component.html',
  styleUrls: ['./assess-part-three.component.scss']
})
export class AssessPartThreeComponent implements OnInit {

  description;
  patientForm;
  constructor(private assessService:AssessService, private router:Router) { }

  ngOnInit(): void {
    this.description=  Constants.assessDescription;
    this.patientForm = this.assessService.getPatientForm();
  }

  next()
  {
    this.router.navigate(['/assess-halomtr'], { });
  }

  back()
  {
    this.router.navigate(['/assess-part-two'], { });
  }

  setYes()
  {
    this.next();
  }

  setNo()
  {
    this.assessService.addDataToSubmitQueueWithNoAberrations();
    this.router.navigate(['/history'], { });
  }
}

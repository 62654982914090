import { Component, OnInit, Input, ViewChild, OnChanges, ElementRef } from '@angular/core';
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { fromEvent, Observable, Subscription } from "rxjs";
import { VertexShader, StarShader } from '@app/shaders';
import { HalomtrService, KnobService  } from '@app/core';

@Component({
  selector: 'app-halomtr-screen-overlay-star',
  templateUrl: './halomtr-screen-overlay-star.component.html',
  styleUrls: ['../halomtr-screen/halomtr-screen.component.scss', './halomtr-screen-overlay-star.component.scss']
})
export class HalomtrScreenOverlayStarComponent implements OnInit {
@Input('bulb')bulb;
@Input('star')star;
@Input('camera')camera;
@Input('examType')examType;
@Input('canvasWidth')canvasWidth;
@Input('canvasHeight')canvasHeight;
@Input('imageWidth')imageWidth;
@Input('imageHeight')imageHeight;
@ViewChild('canvasContainer') canvasContainer:ElementRef;

  scene;
  renderer;
  composer;
  intensity;
  subChanges;
  constructor(private halomtrService:HalomtrService, private knobService:KnobService) { }

  ngOnInit(): void {
    this.intensity = this.halomtrService.retrieveKnobValue(this.star,'starbursts');
    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.animate();

    this.subChanges = this.knobService.onChange.subscribe(res=>{


      if(res.key===this.star.key)
      {
        this.star = res;
        // this.animate();
        this.redraw();
        this.animate();
      }
    })
  }
  ngOnChanges(changes)
  {
    if(this.renderer){
       this.render()
    }
  }

  ngAfterViewInit()
  {
    this.render()
  }

  addElements()
  {
    this.addStar();
  }

  addStar()
  {

      let rad = this.halomtrService.retrieveKnobValue(this.star,'radius');
      this.intensity = this.halomtrService.retrieveKnobValue(this.star,'intensity');
      let thickness = this.halomtrService.retrieveKnobValue(this.star,'thickness');
      let tmp = Math.ceil(this.canvasWidth*rad);


      let geometry = new THREE.PlaneGeometry( tmp, tmp );
      let color = this.bulb.color;
      let position = this.halomtrService.getBulbPosition(this.bulb, this.canvasWidth, this.canvasHeight);

      let uniforms={
        radius:{value: 0.5},
        aspectRatio:{value:1.0},
        thickness: {value: thickness},
        intensity:{value:1.0},
        rotation: {value:0.0},
        base: {value:new THREE.Vector2( 0.5, 0.5 )},//position,//new Uniform( new Vector2(position.x, position.y) ) ,
        color:  {value:new THREE.Vector4( color[0], color[1], color[2], 0.5 )}
      }
      let material =  new THREE.ShaderMaterial({
         uniforms: uniforms,
         fragmentShader: StarShader,
         vertexShader: VertexShader,
       })

      let glare = new THREE.Mesh( geometry, material );


      glare.position.setX(position.x);
      glare.position.setY(position.y);


      this.scene.add( glare );
      this.scene.updateMatrix()


  }

  render()
  {
    this.resizeCanvas();
    this.canvasContainer.nativeElement.append( this.renderer.domElement);
     // this.screenContainer.nativeElement.insertBefore( this.renderer.domElement, this.screenContainer.nativeElement.firstChild );
     // this.renderer.render( this.scene, this.camera );

     this.animate();
  }


  animate() {
     //https://stackoverflow.com/questions/15354117/three-js-blur-the-frame-buffer
     this.composer = new EffectComposer( this.renderer );
     this.composer.addPass( new RenderPass( this.scene, this.camera ) );

     let tdiff = 0.0;
     let vBlur = 0.5 / 512.0;

     this.halomtrService.addBlurEffect(this.composer, tdiff, vBlur, 4)
     this.composer.render();


   }


   resizeCanvas()
   {
     this.renderer.setSize( this.canvasWidth, this.canvasHeight );
     this.scene.remove.apply(this.scene, this.scene.children);
     this.addElements()
   }

   redraw()
   {
     this.scene.remove.apply(this.scene, this.scene.children);
     this.addElements()
   }

}

<!-- <p>counsel-patient-form works!</p> -->


<app-navbar></app-navbar>

<app-page-layout [type]="'counsel'" [description]="description">

  <div class="form-container">
    <app-patient-form [patientForm]="patientForm" [label]="'PLEASE FILL IN PATIENT INFORMATION'" (onNext)="next()" (onBack)="back()"></app-patient-form>
  </div>
</app-page-layout>

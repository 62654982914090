
<div class='image' #screenContainer (resized)="onResized($event)">
  <img [src]="exam.image"/>
  <div class="canvas-container" #canvasContainer>
    <div class="label" *ngIf="label" [ngStyle]="{width:canvasWidth+'px'}">{{label}}</div>
  </div>
  <ng-container *ngIf="isLoaded">
  <ng-container *ngFor="let bulb of exam.bulbs">
    <app-halomtr-screen-overlay-bulb [bulb]="bulb"
                                      [exam]='exam'
                                      [examType]='examType'
                                      [canvasWidth]='canvasWidth'
                                      [canvasHeight]='canvasHeight'
                                      [imageWidth]='imageWidth'
                                      [imageHeight]='imageHeight'
                                      [camera]="camera">
                                    </app-halomtr-screen-overlay-bulb>
  </ng-container>
  </ng-container>
</div>

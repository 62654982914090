
// http://jasonwatmore.com/post/2017/04/19/angular-2-4-router-animation-tutorial-example
// import the required animation functions from the angular animations module
import { trigger, state, animate, query, animateChild, transition, style } from '@angular/animations';

export const splashAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    trigger('fadeOut', [
        transition(':leave', [
            query(':leave', animateChild(), {optional: true}),
            animate(300, style({opacity: 0}))
        ])
    ]);

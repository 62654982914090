import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { switchMap, first, mapTo, timeout, catchError  } from 'rxjs/operators';
import {Observable, of, from, timer} from 'rxjs';
// import { from } from 'rxjs';

// Observable
// of
// fromPromise
// timer
// trigger
@Injectable({
    providedIn: 'root'
})

// https://medium.com/swlh/how-to-develop-a-pwa-splash-screen-with-angular-8-93cbd617b288
export class PwaService {

    constructor(
        private appRef: ApplicationRef,
        private swUpdate: SwUpdate,
    ) {
        if (this.swUpdate.isEnabled) {
            this.appRef.isStable.pipe(
                first(isStable => isStable === true),
                switchMap(() => this.swUpdate.available),
            ).subscribe(() => {
                this.swUpdate.activateUpdate().then(() => document.location.reload());
            });
        }
    }

    checkForUpdate(): Observable<boolean> {
        const waitFor = 10000;

        if (this.swUpdate.isEnabled) {
            const available$ = this.swUpdate.available.pipe(
                mapTo(true),
                timeout(waitFor),
                catchError(() => of(false)),
            );

            return from(this.swUpdate.checkForUpdate()).pipe(
                switchMap(() => available$),
            );
        }

        return timer(waitFor).pipe(mapTo(false));
    }
}

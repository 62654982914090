


<div class="single-knobs-container row">
  <div class="col-12">
    <h4>Radius</h4>
    <app-knob *ngIf="exam.starbursts.radius" [knob]="exam.starbursts.radius" (onChange)="changeRadius($event)"></app-knob>
  </div>
  <div class="col-12">
    <h4>Intensity</h4>
    <app-knob *ngIf="exam.starbursts.intensity" [knob]="exam.starbursts.intensity" (onChange)="changeIntensity($event)"></app-knob>
  </div>

  <div class="col-12">
    <h4>Thickness</h4>
    <app-knob *ngIf="exam.starbursts.thickness" [knob]="exam.starbursts.thickness" (onChange)="changeThickness($event)"></app-knob>
  </div>
</div>

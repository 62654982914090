import { Component } from '@angular/core';
import { AuthService, FeathersService, EducateService, IolService, KnobService } from '@app/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Halomtr';

  isLoggedIn=false;
  constructor(private authService: AuthService,
              private feathersService:FeathersService,
              private educateService:EducateService,
              private knobService:KnobService,
              private iolService:IolService
            ) {}

  ngOnInit()
  {
    this.feathersService.authenticate();
    // check if logged in.
    this.isLoggedIn = this.authService.isLoggedIn();
    this.hydrate();
    this.authService.currentUserChanged.subscribe(res=>{
      this.isLoggedIn = this.authService.isLoggedIn();
      this.hydrate();
    })


  }

  hydrate()
  {
    if(this.isLoggedIn)
    {
      this.educateService.init();
      this.iolService.init();
      this.knobService.init();
    }
  }
}


<div class="page-container">

  <div class="left-page-container">

    <img class="logo" src="/assets/logo/logo.png"/>
  </div>
  <div class="right-page-container">


     <form class='form'  [formGroup]="loginForm" name="Form">
       <div class="form-group">
             <label  class="" >Email</label>
             <input class="form-control" [ngClass]="(showErrors && !loginForm.get('email').valid)?'error':''"  formControlName="email" />
           </div>

           <div class="form-group">
             <label  class="" >Password</label>
             <input type="password" class="form-control" [ngClass]="(showErrors && !loginForm.get('password').valid)?'error':''" formControlName="password" />
           </div>
           <button class="btn btn-dark btn-shadow" (click)="login()">Submit</button>
           <ul class="list-group error-messages">
            <li *ngFor="let m of messages" class="list-group-item">{{m}}</li>
          </ul>
     </form>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HalomtrToolModule } from '@app/halomtr-tool/halomtr-tool.module';
import { SharedModule } from '@app/shared/shared.module';
import { PatientModule } from '@app/patient/patient.module';
import { CounselPatientFormComponent } from './counsel-patient-form/counsel-patient-form.component';
import { CounselHalomtrComponent } from './counsel-halomtr/counsel-halomtr.component';
import { RouterModule, Routes }  from '@angular/router';


@NgModule({
  declarations: [CounselPatientFormComponent, CounselHalomtrComponent],
  imports: [
    CommonModule,
    HalomtrToolModule,
    SharedModule,
    RouterModule,
    PatientModule
  ]
})
export class CounselModule { }



<app-navbar [style]="'assess'"></app-navbar>

<app-page-layout [type]="'assess'" [description]="description">

  <div class="form-container">
    <div class='instructions item'>
      PLEASE SELECT AN OPTION
    </div>
    <div class="item">
      <app-retrieve-patient (onSelect)="selectPatient($event)"></app-retrieve-patient>
      <div *ngIf="patient">
        Found patient #{{patient.prettyId}}
        <br/>
        <button class="btn btn-dark" (click)="proceedWithPatient()">Proceed with patient</button>
      </div>
      <div *ngIf="isNotFound">
        Patient not found
      </div>
    </div>
    <div class="item cce">
      OR
    </div>
    <div class="item cce">
      <button class="btn btn-dark" (click)="nextStep()">Set up new patient</button>
    </div>
  </div>
</app-page-layout>

<!-- part one



// retrieve patient or new

part two
//age
 Eye (OD / OS)
 IOL Manufacturer
 IOl Model
 Lens Power
 UNdilitated scotopic puplil dilation (mm)
 pre-op refraction

Part three
   is the patient experiencing Aberrations?

Part four
  editor -->

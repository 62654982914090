import { Component, Input, OnInit } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { modalEnterAnimation } from '@app/animations/modal-enter.animation';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  animations: [modalEnterAnimation],
  host: { '[@modalEnterAnimation]': '' },
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
@Input('elementName')elementName;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {

  }


  closeModal(state)
  {
    this.activeModal.close(state);
  }

}

import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js';
import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader.js';
import { fromEvent, Observable, Subscription } from "rxjs";
import { VertexShader, HaloShader, GlareShader } from '@app/shaders';
import { HalomtrService  } from '@app/core';

@Component({
  selector: 'app-halomtr-screen-overlay-blurs',
  templateUrl: './halomtr-screen-overlay-blurs.component.html',
  styleUrls: ['./halomtr-screen-overlay-blurs.component.scss']
})
export class HalomtrScreenOverlayBlursComponent implements OnInit {
@Input('bulb')bulb;
@Input('blur')blur;
@Input('camera')camera;
@Input('canvasWidth')canvasWidth;
@Input('canvasHeight')canvasHeight;

scene;
renderer;
// imageWidth=3200;
// imageHeight=3500;
composer;
  constructor(private halomtrService:HalomtrService) { }

  ngOnInit(): void {

    this.scene = new THREE.Scene();

  }

  addBlur()
  {
      let geometry = new THREE.PlaneGeometry( 300, 300 );
      let color = this.bulb.color;
      let position = this.halomtrService.getBulbPosition(this.bulb, this.canvasWidth, this.canvasHeight);

      let uniforms={
        radius:{value: 0.5},
        aspectRatio:{value:1.0},
        thickness: {value: 2},
        intensity:{value:0.5},
        base: {value:new THREE.Vector2( 0.5, 0.5 )},//position,//new Uniform( new Vector2(position.x, position.y) ) ,
        color:  {value:new THREE.Vector4( color[0], color[1], color[2], 0.5 )}
      }
      let material =  new THREE.ShaderMaterial({
         uniforms: uniforms,
         fragmentShader: GlareShader,
         vertexShader: VertexShader,
       })

      let glare = new THREE.Mesh( geometry, material );


      glare.position.setX(position.x);
      glare.position.setY(position.y);


      this.scene.add( glare );
      this.scene.updateMatrix()


  }




}

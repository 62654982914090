import { Injectable } from '@angular/core';

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js';
import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader.js';
import { isNil } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HalomtrService {

  constructor() { }

  getBulbPosition(bulb, canvasWidth, canvasHeight)
  {
    let position = {
      x: bulb.base[0]*canvasWidth-canvasWidth/2,
      y: bulb.base[1]*canvasHeight-canvasHeight/2
    }

    return position;
  }

  addBlurEffect(composer, diffuse, factor, passes)
  {

    let vert = VerticalBlurShader;
    vert.uniforms.tDiffuse.value = diffuse;
    vert.uniforms.v.value = factor;

    let horz = HorizontalBlurShader;
    horz.uniforms.tDiffuse.value = diffuse;
    horz.uniforms.h.value = factor;


    for(let i=0; i<passes; i++)
    {
      let hblur = new ShaderPass( horz );
      hblur.renderToScreen = true;
      composer.addPass( hblur );

      let vblur = new ShaderPass( vert );
      // set this shader pass to render to screen so we can see the effects
      vblur.renderToScreen = true;
       composer.addPass( vblur );
    }



  }

  retrieveKnobValue(knob, key)
  {
    if(!isNil(knob) && !isNil(knob[key]) && !isNil(knob[key].value) )
    {
      return knob[key].value;
    }else{
      return null;
    }
  }


}

import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  history=[
//     {
//  	"type": "assess",
//  	"patient": null,
//  	"patientForm": {
//  		"age": 4,
//  		"patientType": "assess",
//  		"OD": null,
//  		"OS": null,
//  		"selectedEye": "OS",
//  		"prescription": null,
//  		"pupilDilation": 4,
//  		"lensPower": 20,
//  		"preOpRefraction": 2,
//  		"iolManufacturerId": 1,
//  		"iolModelId": 5
//  	},
//  	"assessment": {
//  		"halos": [{
//  			"type": "halos",
//  			"intensity": {
//  				"min": 0,
//  				"max": 1,
//  				"value": 0.8
//  			},
//  			"radius": {
//  				"min": 0,
//  				"max": 0.25,
//  				"value": 0.175
//  			},
//  			"thickness": {
//  				"min": 0,
//  				"max": 0.008,
//  				"value": 0.00104
//  			}
//  		}, {
//  			"type": "halos",
//  			"intensity": {
//  				"min": 0,
//  				"max": 1,
//  				"value": 0.8
//  			},
//  			"radius": {
//  				"min": 0,
//  				"max": 0.25,
//  				"value": 0.175
//  			},
//  			"thickness": {
//  				"min": 0,
//  				"max": 0.008,
//  				"value": 0.00104
//  			}
//  		}],
//  		"glares": {
//  			"type": "glares",
//  			"intensity": {
//  				"min": 0,
//  				"max": 1,
//  				"value": 0.2
//  			},
//  			"radius": {
//  				"min": 0,
//  				"max": 1,
//  				"value": 0.05
//  			},
//  			"thickness": {
//  				"min": 0,
//  				"max": 1,
//  				"value": 0.3
//  			}
//  		},
//  		"starbursts": {
//  			"type": "starbursts",
//  			"intensity": {
//  				"min": 0,
//  				"max": 1,
//  				"value": 0.6
//  			},
//  			"radius": {
//  				"min": 0,
//  				"max": 1,
//  				"value": 0.14
//  			},
//  			"thickness": {
//  				"min": 0,
//  				"max": 0.02,
//  				"value": 0.0026
//  			}
//  		}
//  	},
//  	"createdAt": "2020-07-07T23:38:32.646Z",
//  	"status": "PENDING"
// },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },
//  { "type": "assess", "patient": { "id": 73, "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5, "createdById": 1, "clinicId": 1, "prettyId": "pfrhk9w1j", "updatedAt": "2020-07-15T15:55:50.456Z", "createdAt": "2020-07-15T15:55:50.456Z", "predictionId": null }, "patientForm": { "age": 4, "patientType": "assess", "OD": null, "OS": null, "selectedEye": "OS", "prescription": null, "pupilDilation": 4, "lensPower": 20, "preOpRefraction": 2, "iolManufacturerId": 1, "iolModelId": 5 }, "assessment": { "halos": [ { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } }, { "type": "halos", "intensity": { "min": 0, "max": 1, "value": 0.8 }, "radius": { "min": 0, "max": 0.25, "value": 0.175 }, "thickness": { "min": 0, "max": 0.008, "value": 0.00104 } } ], "glares": { "type": "glares", "intensity": { "min": 0, "max": 1, "value": 0.2 }, "radius": { "min": 0, "max": 1, "value": 0.05 }, "thickness": { "min": 0, "max": 1, "value": 0.3 } }, "starbursts": { "type": "starbursts", "intensity": { "min": 0, "max": 1, "value": 0.6 }, "radius": { "min": 0, "max": 1, "value": 0.14 }, "thickness": { "min": 0, "max": 0.02, "value": 0.0026 } }, "patientId": 73 }, "createdAt": "2020-07-07T23:38:32.646Z", "status": "SUCCESS" },

  ];


  constructor(private dataService:DataService) { }

  getHistory()
  {
    return this.history;
  }
  addToQueue(item)
  {
    this.history.push(item);
  }


  itemHasPatient(item)
  {
    if(!isNil(item) && !isNil(item.patient)&& !isNil(item.patient.id))
    {
      return true;
    }else{
      return false;
    }
  }

  async saveItem(item)
  {

    if(item.status==='PROCESSING' || item.status==='SUCCESS')
    {
      return item;
    }
    try{
      item.status='PROCESSING';

    if(isNil(item))
    {
      return null;
    }
    if(!isNil(item.type))
    {
      if(item.type==='assess')
      {
        let saveResp = await this.saveAssessItem(item);
        item.status='SUCCESS';
        return saveResp;
      }
      if(item.type==='counsel')
      {
        let saveResp = await this.saveCounselItem(item);
        item.status='SUCCESS';
        return saveResp;
      }
    }
    return item;

  }catch(e){
        item.status='ERROR';
        return item;
    }
  }

  async saveOrRetrievePatient(item)
  {
    if(this.itemHasPatient(item))
    {
      return item.patient;
    }
    // save new and return patient;
    if(!isNil(item.patientForm))
    {
      let resp = await this.dataService.create('patients',item.patientForm);
      return resp;
    }

    return null;

  }
  async saveAssessItem(item)
  {
    let patient = await this.saveOrRetrievePatient(item);
    if(!isNil(patient) && !isNil(patient.id))
    {
      let assessment = item.assessment;
      assessment.patientId= patient.id;
      item.patient = patient;
      // assessment.patientId todo
      let assessmentResp = await this.dataService.create('assessments',assessment);
    }
    return item;
  }

  async saveCounselItem(item)
  {
    return item;
  }

  async saveAllPending()
  {
    let self = this;

    let tasks = [];
    for(let item of this.history)
    {
      tasks.push(this.saveItem(item));
    }
    // const tasks   = this.history.map(self.saveItem); // Run all our tasks in parallel.
    const results = await Promise.all(tasks);     // Gather up the results.
    return null;
  }
}

import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DragScrollModule } from 'ngx-drag-scroll';
import { NumberParameterComponent } from './number-parameter/number-parameter.component';
import { TextParameterComponent } from './text-parameter/text-parameter.component';
import { FlatSelectParameterComponent } from './flat-select-parameter/flat-select-parameter.component';
import { IolSelectParameterComponent } from './iol-select-parameter/iol-select-parameter.component';
import { NumberRollParameterComponent } from './number-roll-parameter/number-roll-parameter.component';
import { InputContainerComponent } from './input-container/input-container.component';
import { RollComponent } from './roll/roll.component';
import { NumberSelectParameterComponent } from './number-select-parameter/number-select-parameter.component';



@NgModule({
  declarations: [NumberParameterComponent, TextParameterComponent, FlatSelectParameterComponent, IolSelectParameterComponent, NumberRollParameterComponent, InputContainerComponent, RollComponent, NumberSelectParameterComponent],
  imports: [
    CommonModule,
    DragScrollModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [NumberParameterComponent, TextParameterComponent, FlatSelectParameterComponent, IolSelectParameterComponent, NumberRollParameterComponent, NumberSelectParameterComponent],
})
export class InputModule { }

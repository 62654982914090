

<app-input-container [label]="label" [isValid]="parameterFormGroup.valid" [showErrors]="showErrors">

  <div class="sel" (click)="isShown=true">
    <span *ngIf='parameterFormGroup.value || parameterFormGroup.value===0'>{{parameterFormGroup.value}}</span>
    <span *ngIf='!parameterFormGroup.value && parameterFormGroup.value!==0'>Select</span>
  </div>
</app-input-container>

<!-- isShown: {{isShown}} -->
<div class="full-page-overlay" [ngClass]="isShown?'show':'hide'">
<div class="roll-container " [ngClass]="isShown?'show animated bounceIn':'hide'">

  <h4>{{label}}</h4>
  <app-roll *ngIf="isShown" [min]="min" [max]="max" [increment]="increment" [init]="selected"  (onChange)="change($event)"></app-roll>

  <div class="button-group">
    <button class="btn btn-dark" (click)="save()">Save</button>
    <button class="btn btn-dark" (click)="isShown=false">Cancel</button>
  </div>
</div>
</div>

import {
  Injectable,
  Output,
  EventEmitter,
  PLATFORM_ID,
  Inject,
  Directive,
} from "@angular/core";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Http, Headers } from "@angular/http";
// import {Http} from '@angular/common/http';
// import {Headers} from '@angular/common/headers';

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import getStorage from "./feathers-get-storage";

import localstorage from "feathers-localstorage";
import * as feathers from "@feathersjs/feathers";
import * as feathersRx from "feathers-reactive/lib";
// import * as feathersRx from 'feathers-reactive/dist/feathers-reactive.js';
// import feathersRx from 'feathers-reactive/dist/feathers-reactive';
import * as rest from "@feathersjs/rest-client";
// import * as hooks from 'feathers-hooks';
const hooks = require("feathers-hooks");
// import * as authentication from 'feathers-authentication-client';
import * as authentication from "@feathersjs/authentication-client";

@Directive()
@Injectable({
  providedIn: "root",
})
export class FeathersService {
  @Output("currentUserChanged") currentUserChanged = new EventEmitter(true);

  private _feathers: any;
  private _socket: any;

  private _storage: Storage;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: Http
  ) {
    this._storage = getStorage();

    this._feathers = feathers(); // init Feathers
    //this._feathers.configure(hooks());                // add hooks plugin
    this._feathers.configure(
      feathersRx({
        // add feathers-reactive plugin
        idField: "id",
      })
    );

    this._feathers.configure(
      rest(environment.api_url).angular(this.http, { Headers })
    ); //putback

    this._feathers.configure(
      authentication({
        // add authentication plugin

        storage: this._storage,
        cookie: "feathers-jwt",
      })
    );
  }

  // expose services
  public service(name: string) {
    return this._feathers.service(name);
  }

  // expose authentication
  public authenticate(credentials?): Promise<any> {
    if (isPlatformServer(this.platformId)) {
      return Promise.resolve(false);
    }

    if (isPlatformBrowser(this.platformId)) {
    }
    let token = this._storage.getItem("feathers-jwt");
    if (credentials === undefined && token === null) {
      return Promise.resolve(false);
    }

    return (
      this._feathers
        .authenticate(credentials)
        .then((response) => {
          console.log("response: ", response);
          this._storage.setItem("id_token", response.accessToken);
          this._storage.setItem("accessToken", response.accessToken);
          this._feathers.set("user", response.user);
          this.currentUserChanged.emit(response.user);
          this._storage.setItem("userId", response.user.id);
          this._feathers.passport.verifyJWT(response.accessToken);
          return this._feathers.get("user");

          // return
        })
        // .then((payload) => {
        //   console.log("payload: ", payload);

        //   this._storage.setItem("userId", payload.userId);
        //   return this._feathers.service("users").get(payload.userId);
        // })
        // .then((user) => {
        //   this._feathers.set("user", user);
        //   this.currentUserChanged.emit(user);
        //   return this._feathers.get("user");
        // })
        .catch(function (error) {
          throw error;
        })
    );
  }

  public setToken(token) {
    this._storage.setItem("feathers-jwt", token);
  }
  public getUser() {
    var currentUser = this._feathers.get("user");
    if (typeof currentUser === "undefined") {
      return null;
    } else {
      return currentUser;
    }
  }
  // expose logout
  public logout() {
    localStorage.removeItem("id_token");
    this._feathers.set("user", null);
    this.currentUserChanged.emit(null);
    return this._feathers.logout();
  }
}

import { Component, OnInit } from '@angular/core';
import { KnobService, CounselService } from '@app/core';
import { isNil } from 'lodash';

@Component({
  selector: 'app-counsel-halomtr',
  templateUrl: './counsel-halomtr.component.html',
  styleUrls: ['./counsel-halomtr.component.scss']
})
export class CounselHalomtrComponent implements OnInit {

  examLight;
  examMedium;
  examStrong;
  sub;
  sub2;
  examTypeLight = 'counselLight';
  examTypeMedium = 'counselMedium';
  examTypeStrong = 'counselStrong';
  location='middle';
  prediction;
  numSimilar = 0;
  constructor(private knobService:KnobService, private counselService:CounselService) { }

  ngOnInit(): void {
    this.examLight = this.knobService.getExam(this.examTypeLight);
    this.examMedium = this.knobService.getExam(this.examTypeMedium);
    this.examStrong = this.knobService.getExam(this.examTypeStrong);
    //

    this.sub = this.knobService.onExamChange.subscribe(res=>{
      if(res===this.examTypeLight)
      {
        this.examLight = this.knobService.getExam(this.examTypeLight);
      }

      if(res===this.examTypeMedium)
      {
        this.examMedium = this.knobService.getExam(this.examTypeMedium);
      }

      if(res===this.examTypeStrong)
      {
        this.examStrong = this.knobService.getExam(this.examTypeStrong);
      }

    });

    this.prediction = this.counselService.getPrediction();
    this.computeSimilar();
    this.sub2 = this.counselService.onPredictionChange.subscribe(res=>{
      this.prediction = this.counselService.getPrediction();
      this.computeSimilar();
    })
  }

  setLocation(val)
  {
    this.location = val;
  }

  computeSimilar()
  {
    if(!isNil(this.prediction) && !isNil(this.prediction.response) && !isNil(this.prediction.response.numberSimilarIOL))
    {
      this.numSimilar = this.prediction.response.numberSimilarIOL;
    }
  }

  ngOnDestroy()
  {
    if(this.sub)
    {
      this.sub.unsubscribe();
    }
    if(this.sub2)
    {
      this.sub2.unsubscribe();
    }
  }

}

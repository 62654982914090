


<app-input-container [label]="label" [isValid]="parameterFormGroup.valid" [showErrors]="showErrors">
  <select  class="form-control" [formControl]="parameterFormGroup">
  <option [ngValue]="null" disabled>Select</option>
  <option *ngFor="let prf of options" [ngValue]="prf">
   {{ prf }}
  </option>
</select>
</app-input-container>

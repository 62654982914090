import { Component, Input, OnInit } from '@angular/core';
import { isNil } from 'lodash';

@Component({
  selector: 'app-number-roll-parameter',
  templateUrl: './number-roll-parameter.component.html',
  styleUrls: ['./number-roll-parameter.component.scss']
})
export class NumberRollParameterComponent implements OnInit {
@Input('label')label;
@Input('min')min=0.0;
@Input('max')max=1.0;
@Input('showErrors')showErrors=false;
@Input('init')init=0.5;
@Input('increment')increment=0.1;
@Input('parameterFormGroup')parameterFormGroup;

  isShown=false;
  selected;
  isLoaded=false;

  constructor() { }

  ngOnInit(): void {

    if(!isNil(this.parameterFormGroup.value))
    {
      this.selected= this.parameterFormGroup.value;
    }else{
      this.selected= this.init;
    }
  }

  change(val)
  {
    this.selected = val;
  }

  save()
  {
    this.parameterFormGroup.setValue(this.selected);
    this.isShown = false;
  }

}

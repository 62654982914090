

<div class="knob-container">
  <div class="tab-select-list">
    <div class="tab-item" [ngClass]="tab==='HALO'?'active':''" (click)="setTab('HALO')">Halo</div>
    <div class="tab-item" [ngClass]="tab==='BLUR'?'active':''" (click)="setTab('BLUR')">Blur</div>
    <div class="tab-item" [ngClass]="tab==='STAR'?'active':''" (click)="setTab('STAR')">Starburst</div>
  </div>

  <div class="knobs-container" [ngClass]="tab==='HALO'?'active':''">
    <app-halo-knobs [examType]="examType"></app-halo-knobs>
  </div>
  <div class="knobs-container" [ngClass]="tab==='BLUR'?'active':''">
    <app-blur-knobs [examType]="examType"></app-blur-knobs>
  </div>
  <div class="knobs-container" [ngClass]="tab==='STAR'?'active':''">
    <app-star-knobs [examType]="examType"></app-star-knobs>
  </div>
</div>
